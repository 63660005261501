import { nihApiTypes } from '../types/nihApiTypes';

// define url params for nih api
const NIH_CONDITIONS_PARAMS = [
  'primary_name',
  'consumer_name',
  'key_id',
  'icd10cm_codes',
  'icd10cm',
  'term_icd9_code',
  'term_icd9_text',
  'word_synonyms',
  'synonyms',
  'info_link_data',
];
const NIH_CONDITIONS_LABEL = 'consumer_name';
const NIH_CONDITIONS_VALUE = 'icd10cm_codes';

const NIH_DIAGNOSIS_PARAMS = ['code', 'name'];
const NIH_DIAGNOSIS_LABEL = 'name';
const NIH_DIAGNOSIS_VALUE = 'code';

const NIH_PROCEDURES_PARAMS = [
  'primary_name',
  'consumer_name',
  'key_id',
  'term_icd9_code',
  'term_icd9_text',
  'word_synonyms',
  'synonyms',
  'info_link_data',
];
const NIH_PROCEDURES_LABEL = 'consumer_name';
const NIH_PROCEDURES_VALUE = 'term_icd9_code';

const NIH_RXTERMS_PARAMS = ['DISPLAY_NAME', 'STRENGTHS_AND_FORMS', 'RXCUIS', 'DISPLAY_NAME_SYNONYM'];
const NIH_RXTERMS_LABEL = 'DISPLAY_NAME';
const NIH_RXTERMS_VALUE = 'RXCUIS';

// get api proxy path and include terms param
export const getProxyApiPath = (type, terms) => {
  switch (type) {
    case nihApiTypes.NIH_CONDITIONS:
      return `proxy/api/conditions/v3/search?sf=${NIH_CONDITIONS_PARAMS.join(',')}&df=${NIH_CONDITIONS_PARAMS.join(',')}&terms=${terms}`;

    case nihApiTypes.NIH_DIAGNOSIS:
      return `proxy/api/icd10cm/v3/search?sf=${NIH_DIAGNOSIS_PARAMS.join(',')}&df=${NIH_DIAGNOSIS_PARAMS.join(',')}&terms=${terms}`;

    case nihApiTypes.NIH_PROCEDURES:
      return `proxy/api/procedures/v3/search?sf=${NIH_PROCEDURES_PARAMS.join(',')}&df=${NIH_PROCEDURES_PARAMS.join(',')}&terms=${terms}`;

    case nihApiTypes.NIH_RXTERMS:
      return `proxy/api/rxterms/v3/search?sf=${NIH_RXTERMS_PARAMS.join(',')}&df=${NIH_RXTERMS_PARAMS.join(',')}&terms=${terms}`;

    default:
      break;
  }
};

// NIH response contains an array w/ nested data array[<Object>] at index 3
// map all available data into return object
export const mapNihDataToSelectOptions = (type, data) => {
  const RESPONSE_DATA_INDEX = 3;
  let responseItemList = data[RESPONSE_DATA_INDEX];

  switch (type) {
    case nihApiTypes.NIH_CONDITIONS:
      return responseItemList.map(stringList => {
        const mapObj = {};
        stringList.forEach((string, i) => (mapObj[NIH_CONDITIONS_PARAMS[i]] = string));
        // provide label and value for select fields
        mapObj.label = mapObj[NIH_CONDITIONS_LABEL];
        mapObj.value = mapObj[NIH_CONDITIONS_VALUE];
        return mapObj;
      });

    case nihApiTypes.NIH_DIAGNOSIS:
      return responseItemList.map(stringList => {
        const mapObj = {};
        stringList.forEach((string, i) => (mapObj[NIH_DIAGNOSIS_PARAMS[i]] = string));
        // provide label and value for select fields
        mapObj.label = mapObj[NIH_DIAGNOSIS_LABEL];
        mapObj.value = mapObj[NIH_DIAGNOSIS_VALUE];
        return mapObj;
      });

    case nihApiTypes.NIH_PROCEDURES:
      return responseItemList.map(stringList => {
        const mapObj = {};
        stringList.forEach((string, i) => (mapObj[NIH_PROCEDURES_PARAMS[i]] = string));
        // provide label and value for select fields
        mapObj.label = mapObj[NIH_PROCEDURES_LABEL];
        mapObj.value = mapObj[NIH_PROCEDURES_VALUE] !== '' ? mapObj[NIH_PROCEDURES_VALUE] : mapObj['key_id'];
        return mapObj;
      });

    case nihApiTypes.NIH_RXTERMS:
      return responseItemList.map(stringList => {
        const mapObj = {};
        stringList.forEach((string, i) => (mapObj[NIH_RXTERMS_PARAMS[i]] = string));
        // provide label and value for select fields
        mapObj.label = mapObj[NIH_RXTERMS_LABEL];
        mapObj.value = mapObj[NIH_RXTERMS_VALUE];
        return mapObj;
      });

    default:
      return [];
  }
};

// get display label and value either from server or from page update
// data: array of json objects formatted with key names (i.e. not NIH response)
export const mapJsonArrayToSelectOptions = (type, data) => {
  switch (type) {
    case nihApiTypes.NIH_CONDITIONS:
      return data.map(item => {
        const mapObj = { ...item };
        if (mapObj.isManualEntry === true || !mapObj[NIH_CONDITIONS_LABEL]) {
          // manually entered value
          // could come from server or from page edit
          if (mapObj.text && mapObj.id) {
            mapObj.label = mapObj.text;
            mapObj.value = mapObj.id;
          } else if (mapObj.text) {
            mapObj.label = mapObj.text;
            mapObj.value = mapObj.text;
          }
        } else {
          // NIH object
          // provide label and value for select fields
          mapObj.label = item[NIH_CONDITIONS_LABEL];
          mapObj.value = item[NIH_CONDITIONS_VALUE];
        }
        return mapObj;
      });

    case nihApiTypes.NIH_DIAGNOSIS:
      return data.map(item => {
        const mapObj = { ...item };
        if (mapObj.isManualEntry === true || !mapObj[NIH_DIAGNOSIS_LABEL]) {
          // manually entered value
          // could come from server or from page edit
          if (mapObj.text && mapObj.id) {
            mapObj.label = mapObj.text;
            mapObj.value = mapObj.id;
          } else if (mapObj.text) {
            mapObj.label = mapObj.text;
            mapObj.value = mapObj.text;
          }
        } else {
          // NIH object
          // provide label and value for select fields
          mapObj.label = item[NIH_DIAGNOSIS_LABEL];
          mapObj.value = item[NIH_DIAGNOSIS_VALUE];
        }
        return mapObj;
      });

    case nihApiTypes.NIH_PROCEDURES:
      return data.map(item => {
        const mapObj = { ...item };
        if (mapObj.isManualEntry === true || !mapObj[NIH_PROCEDURES_LABEL]) {
          // manually entered value
          // could come from server or from page edit
          if (mapObj.text && mapObj.id) {
            mapObj.label = mapObj.text;
            mapObj.value = mapObj.id;
          } else if (mapObj.text) {
            mapObj.label = mapObj.text;
            mapObj.value = mapObj.text;
          }
        } else {
          // NIH object
          // provide label and value for select fields

          // SL: some procedures come back with no icd9 code
          // in those cases, use key_id as fallback
          mapObj.label = item[NIH_PROCEDURES_LABEL];
          mapObj.value = item[NIH_PROCEDURES_VALUE] !== '' ? item[NIH_PROCEDURES_VALUE] : item['key_id'];
        }
        return mapObj;
      });

    case nihApiTypes.NIH_RXTERMS:
      return data.map(item => {
        const mapObj = { ...item };
        if (mapObj.isManualEntry === true || !mapObj[NIH_RXTERMS_LABEL]) {
          // manually entered value
          // could come from server or from page edit
          if (mapObj.text && mapObj.id) {
            mapObj.label = mapObj.text;
            mapObj.value = mapObj.id;
          } else if (mapObj.text) {
            mapObj.label = mapObj.text;
            mapObj.value = mapObj.text;
          }
        } else {
          // NIH object
          // provide label and value for select fields
          mapObj.label = item[NIH_RXTERMS_LABEL];
          mapObj.value = item[NIH_RXTERMS_VALUE];
        }
        return mapObj;
      });

    default:
      return [];
  }
};

// map select field data outbound for storage in db
// dont save label/value in db
export const mapOutboundNihData = data => {
  return data.map(item => {
    // guarantee we are working on a copy of nested data
    let updateItem = { ...item };

    if (updateItem.isManualEntry === true) {
      // manual entry
      if (!updateItem.text && !updateItem.id) {
        updateItem = {
          id: item.value,
          text: item.label,
          isManualEntry: item.isManualEntry,
        };
      }
    } else {
      // nih api object - no need to store value/label
      delete updateItem.value;
      delete updateItem.label;
    }

    if (item.family_member) {
      updateItem.family_member = item.family_member;
    }

    return updateItem;
  });
};

// map json objects to list of labels
// input: data array of objects
// output: string
// try and use an NIH label if one is present,
// if not then assume manual entry and use text
// if no text is present, fall back to label or blank
export const mapJsonToLabelList = (type, data) => {
  if (!data || !data.length > 0) return [];

  switch (type) {
    case nihApiTypes.NIH_CONDITIONS:
      return data
        .map(item => {
          let label = item[NIH_CONDITIONS_LABEL];
          return label ? label : item.text ? item.text : item.label ? item.label : '';
        })
        .join(', ');

    case nihApiTypes.NIH_DIAGNOSIS:
      return data
        .map(item => {
          let label = item[NIH_DIAGNOSIS_LABEL];
          return label ? label : item.text ? item.text : item.label ? item.label : '';
        })
        .join(', ');

    case nihApiTypes.NIH_PROCEDURES:
      return data
        .map(item => {
          let label = item[NIH_PROCEDURES_LABEL];
          return label ? label : item.text ? item.text : item.label ? item.label : '';
        })
        .join(', ');

    case nihApiTypes.NIH_RXTERMS:
      return data
        .map(item => {
          let label = item[NIH_RXTERMS_LABEL];
          return label ? label : item.text ? item.text : item.label ? item.label : '';
        })
        .join(', ');

    default:
      return '';
  }
};

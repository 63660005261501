import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles, Typography, Card, Grid, Icon } from '@material-ui/core';
import { isNil } from 'lodash';
import classNames from 'classnames';
import { compose } from 'recompose';

import {
  sendPatientMessage as sendPatientMessageAction,
  callPatient as callPatientAction,
  CALL_PATIENT_SUCCESS,
} from '../visit/visit.actions';
import {
  clearSelectedVisit as clearSelectedVisitAction,
  showToast as showToastAction,
  showConfirmChargesModal as showConfirmChargesModalAction,
  setBypassVisitPrompt as setBypassVisitPromptAction,
  setSelectedVisit as setSelectedVisitAction,
} from '../layout/layout.actions';
import {
  setVisitStatus as setVisitStatusAction,
  getBoardItems as getBoardItemsAction,
  getNewBoardItems,
  getAssignedBoardItems,
  getActiveBoardItems,
  SET_VISIT_STATUS_SUCCESS,
} from '../board/board.actions';
import CustomDialog from '../../common/customDialog/customDialog.component';
import MessageDialog from '../../common/messageDialog/messageDialog.component';
import { visitStatusTypes } from '../../types/visitStatusTypes';
import { logEvent } from '../../utilities/googleAnalytics';
import PatientBannerActions from './patientBannerActions.component';
import PatientBannerSummary from './patientBannerSummary.component';

const DIALOG_KEYS = {
  IS_SHOW_CANCEL_VISIT_DIALOG: 'isShowCancelVisitDialog',
  IS_SHOW_CONVERT_TO_VOICE_DIALOG: 'isShowConvertToVoiceDialog',
  IS_SHOW_MESSAGE_DIALOG: 'isShowMessageDialog',
  IS_SHOW_RETURN_TO_WAITING_ROOM_DIALOG: 'isShowReturnToWaitingRoomDialog',
};

class PatientBanner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      [DIALOG_KEYS.IS_SHOW_CONVERT_TO_VOICE_DIALOG]: false,
      [DIALOG_KEYS.IS_SHOW_MESSAGE_DIALOG]: false,
      [DIALOG_KEYS.IS_SHOW_RETURN_TO_WAITING_ROOM_DIALOG]: false,
      [DIALOG_KEYS.IS_SHOW_CANCEL_VISIT_DIALOG]: false,
      isCancelButStillChart: false,
      isUnreached: false,
    };
  }

  refreshBoard = () => {
    this.props.getNewBoardItems();
    this.props.getAssignedBoardItems();
    this.props.getActiveBoardItems();
  };

  handleToggleDialog = dialogKey =>
    this.setState(prevState => ({
      [dialogKey]: !prevState[dialogKey],
    }));

  handleToggleCancelVisitDialog = (isCancelButStillChart = false) =>
    this.setState(prevState => ({
      [DIALOG_KEYS.IS_SHOW_CANCEL_VISIT_DIALOG]: !prevState[DIALOG_KEYS.IS_SHOW_CANCEL_VISIT_DIALOG],
      isCancelButStillChart,
    }));

  handleSendMessage = msg => {
    this.props.sendPatientMessage(this.props.selectedVisit.id, msg);
    this.handleToggleDialog(DIALOG_KEYS.IS_SHOW_MESSAGE_DIALOG);
  };

  handleCancelVisit = async () => {
    this.handleToggleCancelVisitDialog();

    const {
      history,
      providerId,
      selectedVisit: { facility, id: visitId, state },
      setVisitStatus,
      clearSelectedVisit,
      showConfirmChargesModal,
      showToast,
      getBoardItems,
    } = this.props;

    // used for event logging
    const waitingState = state === visitStatusTypes.SELECTED ? 'waiting' : 'started';

    if (this.state.isCancelButStillChart) {
      logEvent('cancel_visit_chart', {
        providerId,
        visitId,
        waitingState,
      });

      const isFacilitatedVisit = !isNil(facility);

      const response = await setVisitStatus(visitId, visitStatusTypes.COMPLETED);
      if (response.type === SET_VISIT_STATUS_SUCCESS) {
        await clearSelectedVisit();
        if (!isFacilitatedVisit) {
          showConfirmChargesModal();
        }
        history.push(`/previous/${visitId}`);
      } else {
        showToast('Could not cancel visit. Please check your connection and try again.');
      }
    } else {
      logEvent('cancel_visit', {
        providerId,
        visitId,
        waitingState,
      });
      await setVisitStatus(visitId, visitStatusTypes.CANCELED);
      await clearSelectedVisit();
      this.refreshBoard();
    }
  };

  handleReturnToWaitingRoom = async () => {
    const { selectedVisit, providerId } = this.props;
    const { isUnreached } = this.state;

    logEvent('return_waitingroom', {
      providerId,
      visitId: selectedVisit.id,
      waitingState: selectedVisit.state === visitStatusTypes.SELECTED ? 'waiting' : 'started',
    });

    const response = await this.props.setVisitStatus(selectedVisit.id, visitStatusTypes.NEW, isUnreached);

    if (response.type === SET_VISIT_STATUS_SUCCESS) {
      this.setState({ [DIALOG_KEYS.IS_SHOW_RETURN_TO_WAITING_ROOM_DIALOG]: false }, async () => {
        this.props.clearSelectedVisit();
        this.refreshBoard();

        // bypass visit exit prompt if currently in visit
        await this.props.setBypassVisitPrompt(true);
        this.props.history.replace('/');
      });
    } else {
      alert('Unable to update the visit');
      this.handleToggleDialog(DIALOG_KEYS.IS_SHOW_RETURN_TO_WAITING_ROOM_DIALOG);
    }
  };

  getBackgroundColor = () => {
    const {
      selectedVisit: { isVoiceCall, state },
      theme,
    } = this.props;

    // if (state === visitStatusTypes.STARTED) return `${theme.palette.primary.green}40`;
    // if (isVoiceCall) return `${theme.palette.primary.orange}40`; // This adds 25% opacity

    return theme.palette.primary.lightBlue;
  };

  handleStartVisit = () => {
    const { selectedVisit, providerId, setVisitStatus } = this.props;

    logEvent('startvisit', {
      visitId: selectedVisit.id,
      providerId,
    });

    setVisitStatus(selectedVisit.id, visitStatusTypes.STARTED);
  };

  handleConvertToVoice = async () => {
    const { selectedVisit, callPatient, setSelectedVisit } = this.props;

    const { type } = await callPatient(selectedVisit.id);

    if (type === CALL_PATIENT_SUCCESS) setSelectedVisit({ ...selectedVisit, isVoiceCall: true });

    this.handleToggleDialog(DIALOG_KEYS.IS_SHOW_CONVERT_TO_VOICE_DIALOG);
  };

  render() {
    const { classes, location, theme, isLoading, providerId, selectedVisit, callPatient } = this.props;
    const {
      isShowMessageDialog,
      isShowCancelVisitDialog,
      isShowReturnToWaitingRoomDialog,
      isShowConvertToVoiceDialog,
      isUnreached,
    } = this.state;

    // only show banner if we have a selected visit and not on previous chart details
    const pathnames = location.pathname.split('/');
    if (isNil(selectedVisit) || (pathnames[1] === 'previous' && pathnames[2])) return null;

    const { isVoiceCall, state } = selectedVisit;

    const showBanner = state === visitStatusTypes.SELECTED || state === visitStatusTypes.READY || state === visitStatusTypes.STARTED;
    const backgroundColor = this.getBackgroundColor();
    const iconColor = isVoiceCall ? theme.palette.primary.orange : theme.palette.primary.main;

    return (
      <>
        {showBanner && (
          <Card className={classes.card} style={{ backgroundColor }}>
            <Grid xs={12} container alignItems="center" justify="space-between">
              <Grid className={classes.actionsContainer} item>
                <PatientBannerActions
                  disabled={isLoading}
                  providerId={providerId}
                  selectedVisit={selectedVisit}
                  onCancelVisitClick={this.handleToggleCancelVisitDialog}
                  onConvertToVoiceClick={() => this.handleToggleDialog(DIALOG_KEYS.IS_SHOW_CONVERT_TO_VOICE_DIALOG)}
                  onMessageClick={() => this.handleToggleDialog(DIALOG_KEYS.IS_SHOW_MESSAGE_DIALOG)}
                  onStartVisit={this.handleStartVisit}
                  onRedialClick={() => callPatient(selectedVisit.id)}
                  onWaitingRoomClick={(unreached = false) => {
                    this.setState({ isUnreached: unreached }, this.handleToggleDialog(DIALOG_KEYS.IS_SHOW_RETURN_TO_WAITING_ROOM_DIALOG));
                  }}
                />
              </Grid>
              {/*<Grid item>*/}
              {/*  <Icon*/}
              {/*    className={classNames({ 'fal fa-phone-volume': isVoiceCall, 'fal fa-video': !isVoiceCall }, classes.phoneTypeIcon)}*/}
              {/*    style={{ color: iconColor }}*/}
              {/*  />*/}
              {/*</Grid>*/}
              <PatientBannerSummary selectedVisit={selectedVisit} />
            </Grid>
          </Card>
        )}
        <MessageDialog
          open={isShowMessageDialog}
          handleClose={() => this.handleToggleDialog(DIALOG_KEYS.IS_SHOW_MESSAGE_DIALOG)}
          handleAction={this.handleSendMessage}
        />
        <CustomDialog
          content={
            <Typography variant="subtitle1" id="modal-title">
              {isUnreached
                ? 'Are you sure you want to return the patient to the waiting room as unreached?'
                : 'Are you sure you want to return the patient to the waiting room?'}
            </Typography>
          }
          open={isShowReturnToWaitingRoomDialog}
          title="Return to Waiting Room"
          handleClose={() => this.handleToggleDialog(DIALOG_KEYS.IS_SHOW_RETURN_TO_WAITING_ROOM_DIALOG)}
          handleAction={this.handleReturnToWaitingRoom}
        />
        <CustomDialog
          content={
            <Typography variant="subtitle1" id="modal-title">
              Are you sure you want to cancel this visit?
            </Typography>
          }
          open={isShowCancelVisitDialog}
          title={this.state.isCancelButStillChart ? 'Cancel Visit But Still Chart' : 'Cancel Visit'}
          handleClose={this.handleToggleCancelVisitDialog}
          handleAction={this.handleCancelVisit}
        />
        <CustomDialog
          content={
            <Typography variant="subtitle1" id="modal-title">
              Are you sure you want to covert this visit to a voice call? This action cannot be undone.
            </Typography>
          }
          open={isShowConvertToVoiceDialog}
          title="Convert to Voice Call"
          handleClose={() => this.handleToggleDialog(DIALOG_KEYS.IS_SHOW_CONVERT_TO_VOICE_DIALOG)}
          handleAction={this.handleConvertToVoice}
        />
      </>
    );
  }
}

const styles = theme => ({
  card: {
    borderRadius: 0,
    padding: theme.spacing.unit * 2,
    overflow: 'unset',
  },
  actionsContainer: {
    '@media (max-width: 960px)': {
      flex: '1 0 100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  phoneTypeIcon: {
    fontSize: '2.5rem',
    paddingRight: '1rem',
  },
});

PatientBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  isLoading: PropTypes.bool.isRequired,
  providerId: PropTypes.string.isRequired,
  selectedVisit: PropTypes.object,

  clearSelectedVisit: PropTypes.func.isRequired,
  callPatient: PropTypes.func.isRequired,
  getBoardItems: PropTypes.func.isRequired,
  getNewBoardItems: PropTypes.func.isRequired,
  getAssignedBoardItems: PropTypes.func.isRequired,
  getActiveBoardItems: PropTypes.func.isRequired,
  sendPatientMessage: PropTypes.func.isRequired,
  setBypassVisitPrompt: PropTypes.func.isRequired,
  setSelectedVisit: PropTypes.func.isRequired,
  setVisitStatus: PropTypes.func.isRequired,
  showConfirmChargesModal: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
};

PatientBanner.defaultProps = {
  selectedVisit: null,
};

const mapStateToProps = state => ({
  isLoading: state.board.isLoading || state.visit.isLoading,
  providerId: state.provider.providerId,
  selectedVisit: state.layout.selectedVisit,
});

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, {
    clearSelectedVisit: clearSelectedVisitAction,
    callPatient: callPatientAction,
    getBoardItems: getBoardItemsAction,
    getNewBoardItems,
    getAssignedBoardItems,
    getActiveBoardItems,
    sendPatientMessage: sendPatientMessageAction,
    setBypassVisitPrompt: setBypassVisitPromptAction,
    setSelectedVisit: setSelectedVisitAction,
    setVisitStatus: setVisitStatusAction,
    showConfirmChargesModal: showConfirmChargesModalAction,
    showToast: showToastAction,
  })
)(PatientBanner);

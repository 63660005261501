import { connect } from 'react-redux';
import { withRouter, Prompt } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Grid from '@material-ui/core/Grid';
import TwilioVideo from 'twilio-video';
import classNames from 'classnames';
import { isNil } from 'lodash';
import ReactRouterPropTypes from 'react-router-prop-types';
import { compose } from 'recompose';
import { Typography } from '@material-ui/core';
import CustomDialog from '../../common/customDialog/customDialog.component';
import { getPreviousVisit as getPreviousVisitAction, GET_PREVIOUS_VISIT_SUCCESS } from '../chart/chart.actions';
import { logEvent } from '../../utilities/googleAnalytics';
import ChartDetails from '../chart/chartDetails.component';
import {
  clearSelectedVisit as clearSelectedVisitAction,
  showConfirmChargesModal as showConfirmChargesModalAction,
  setPageTitle as setPageTitleAction,
  setBypassVisitPrompt as setBypassVisitPromptAction,
  setSelectedVisit as setSelectedVisitAction,
} from '../layout/layout.actions';
import { setVisitStatus as setVisitStatusAction, SET_VISIT_STATUS_SUCCESS } from '../board/board.actions';
import { visitStatusTypes } from '../../types/visitStatusTypes';
import {
  getTwilioVideoAuth as getTwilioVideoAuthAction,
  GET_TWILIO_VIDEO_AUTH_SUCCESS,
  callPatient as callPatientAction,
  CALL_PATIENT_SUCCESS,
  setVideoConnectionStatus as setVideoConnectionStatusAction,
} from './visit.actions';
import VisitCallControls from './visitCallControls.component';
import VisitStatus from './visitStatus.component';
import { TRACK_TYPES } from '../../types/visitTrackTypes';

const LOCAL_MEDIA_HEIGHT = 160;
const LOCAL_MEDIA_RATIO = 4 / 3;
const LARGE_MEDIA_WIDTH = 512;
const SMALL_MEDIA_WIDTH = 400;

const DIALOG_KEYS = {
  IS_SHOW_CALL_DIALOG: 'isShowCallDialog',
  IS_SHOW_CONVERT_TO_VOICE_DIALOG: 'isShowConvertToVoiceDialog',
};

const MEDIA_IDS = {
  LOCAL_MEDIA: 'local-media',
  LOCAL_VIDEO: 'local-video',
  PARTICIPANT_MEDIA: 'participant-media',
  PARTICIPANT_VIDEO: 'participant-video',
};

class VisitContainer extends Component {
  state = {
    hasParticipants: false,
    hasRetry: false,
    isConnected: false,
    isConnecting: true,
    isShowCallDialog: false,
    isShowConvertToVoiceDialog: false,
  };

  async componentDidMount() {
    const { history, match, setBypassVisitPrompt, setPageTitle, getPreviousVisit, getTwilioVideoAuth } = this.props;

    window.addEventListener('rmd.patient_banner.end_visit', this.handleEndVisitPrompt);
    window.addEventListener('rmd.patient_banner.initiate_video', this.initiateVideo);
    window.addEventListener('rmd.patient_banner.disconnect_video', this.handleDisconnect);

    // reset layout prompt-blocking state
    setBypassVisitPrompt(false);
    setPageTitle('Patient Visit');

    // check visit status before attempting to connect to twilio room
    const { visitId } = match.params;
    const visitResponse = await getPreviousVisit(visitId);

    if (
      visitResponse.type === GET_PREVIOUS_VISIT_SUCCESS &&
      (visitResponse.response.state === visitStatusTypes.STARTED ||
        visitResponse.response.state === visitStatusTypes.SELECTED ||
        visitResponse.response.state === visitStatusTypes.READY)
    ) {
      const action = localStorage.getItem(`visit_${visitId}_action`) || 'view';

      if (action === 'phone') {
        window.dispatchEvent(new CustomEvent('rmd.patient_banner.initiate_phone'));
      } else if (action === 'video') {
        window.dispatchEvent(new CustomEvent('rmd.patient_banner.initiate_video'));
      } else {
        window.dispatchEvent(new CustomEvent('rmd.patient_banner.initiate_view'));
      }

      localStorage.removeItem(`visit_${visitId}_action`);
    } else {
      history.replace(`/previous/${visitId}`);
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedVisit } = this.props;

    if (!isNil(prevProps.selectedVisit) && !isNil(selectedVisit) && !prevProps.selectedVisit.isVoiceCall && selectedVisit.isVoiceCall) {
      this.localVideoTrack.stop();
      this.localVideoTrack.detach();

      this.room.localParticipant.unpublishTrack(this.localVideoTrack);

      const localVideo = document.getElementById(MEDIA_IDS.LOCAL_VIDEO);
      if (!isNil(localVideo)) localVideo.parentNode.removeChild(localVideo);

      const participantVideo = document.getElementById(MEDIA_IDS.PARTICIPANT_VIDEO);
      if (!isNil(participantVideo)) participantVideo.parentNode.removeChild(participantVideo);
    }
  }

  componentWillUnmount() {
    this.props.setVideoConnectionStatus('disconnected');
    window.removeEventListener('rmd.patient_banner.end_visit', this.handleEndVisitPrompt);
    window.removeEventListener('rmd.patient_banner.initiate_video', this.initiateVideo);
    window.removeEventListener('rmd.patient_banner.disconnect_video', this.handleDisconnect);

    if (this.state.isConnected) {
      this.handleDisconnect();
    }
  }

  initiateVideo = async () => {
    this.props.setVideoConnectionStatus('connecting');
    const response = await this.props.getTwilioVideoAuth(this.props.match.params.visitId);

    if (response.type === GET_TWILIO_VIDEO_AUTH_SUCCESS) {
      this.connectToRoom();
    }
  };

  handleDisconnect = () => {
    try {
      console.log('disconnecting room');
      try {
        const { selectedVisit } = this.props;

        this.room.localParticipant.unpublishTrack(this.localVideoTrack);

        this.localVideoTrack.stop();
        this.localVideoTrack.detach().forEach(item => item.remove());

        this.room.localParticipant.unpublishTrack(this.localAudioTrack);

        this.localAudioTrack.stop();
        this.localAudioTrack.detach().forEach(item => item.remove());

        this.room.disconnect();

        // TODO: need to remove the vido and audio elements from the localMediaContainer, and possibly remove the participant ones when they disconnect
        // or reconnect
        this.props.setVideoConnectionStatus('disconnected');
        this.setState({ isConnected: false });
      } catch (e) {
        console.log('ERROR DISCONNECTING', e);
      }
    } catch (e) {
      console.log('e!', e);
    }
  };

  connectToRoom = () => {
    const {
      setVideoConnectionStatus,
      match: {
        params: { visitId },
      },
      selectedVisit,
      twilioVideoAuth,
    } = this.props;

    setVideoConnectionStatus('connecting');

    TwilioVideo.connect(twilioVideoAuth.token, {
      name: visitId,
      tracks: [],
      video: !selectedVisit.isVoiceCall,
      preferredVideoCodecs: ['H264'],
    }).then(
      room => {
        this.room = room;
        const self = this;
        this.setState({ isConnected: true, isConnecting: false });
        // console.log(`Successfully joined a Room: ${room}`, room);

        if (!selectedVisit.isVoiceCall) {
          TwilioVideo.createLocalVideoTrack().then(track => {
            const localMediaContainer = document.getElementById(MEDIA_IDS.LOCAL_MEDIA);
            if (localMediaContainer) {
              localMediaContainer.appendChild(track.attach());

              const elements = localMediaContainer.getElementsByTagName(TRACK_TYPES.VIDEO);
              if (elements.length > 0) {
                elements[0].id = MEDIA_IDS.LOCAL_VIDEO;
                elements[0].width = LOCAL_MEDIA_HEIGHT * LOCAL_MEDIA_RATIO;
                elements[0].height = LOCAL_MEDIA_HEIGHT;
              }

              this.localVideoTrack = track;
              room.localParticipant.publishTrack(track);
            }
          });
        }

        TwilioVideo.createLocalAudioTrack().then(track => {
          const localMediaContainer = document.getElementById(MEDIA_IDS.LOCAL_MEDIA);
          if (localMediaContainer) {
            localMediaContainer.appendChild(track.attach());

            this.localAudioTrack = track;
            room.localParticipant.publishTrack(track);
          }
        });

        function participantConnected(participant) {
          console.log('Participant "%s" connected', participant.identity);

          const container = document.getElementById(MEDIA_IDS.PARTICIPANT_MEDIA);
          const div = document.createElement('div');
          div.id = participant.sid;

          participant.on('trackSubscribed', track => trackSubscribed(div, track));
          participant.on('trackUnsubscribed', trackUnsubscribed);

          participant.tracks.forEach(publication => {
            if (publication.isSubscribed) {
              trackSubscribed.bind(this, div, publication.track);
            }
          });

          if (container) {
            container.appendChild(div);
          }

          self.setState({ hasParticipants: true });
        }

        function participantDisconnected(participant) {
          console.log('Participant "%s" disconnected', participant.identity);
          const participantElem = document.getElementById(participant.sid);
          if (participantElem) {
            participantElem.remove();
          }

          self.setState({ hasParticipants: false });
        }

        function trackSubscribed(div, track) {
          if (track.kind === TRACK_TYPES.VIDEO || track.kind === TRACK_TYPES.AUDIO) {
            if (track.kind === TRACK_TYPES.VIDEO) {
              const videoElement = document.getElementById(MEDIA_IDS.PARTICIPANT_VIDEO);
              if (!isNil(videoElement)) div.removeChild(videoElement);
            }

            div.appendChild(track.attach());

            const elements = div.getElementsByTagName(TRACK_TYPES.VIDEO);
            if (elements.length > 0) {
              elements[0].id = MEDIA_IDS.PARTICIPANT_VIDEO;
              elements[0].width = this && this.mediaSideDiv ? SMALL_MEDIA_WIDTH : LARGE_MEDIA_WIDTH;
            }
          }
        }

        function trackUnsubscribed(track) {
          if (track.kind === TRACK_TYPES.VIDEO || track.kind === TRACK_TYPES.AUDIO) track.detach().forEach(element => element.remove());
        }

        function participantReconnecting(participant) {
          console.log('participantReconnecting', participant);
        }

        room.participants.forEach(participantConnected);
        room.on('participantConnected', participantConnected);
        room.on('participantReconnecting', participantReconnecting);

        room.on('participantDisconnected', participantDisconnected);
        room.once('disconnected', (/* error */) => room.participants.forEach(participantDisconnected));

        setTimeout(() => {
          setVideoConnectionStatus('connected');
        }, 7500);
      },
      error => {
        console.error(`Unable to connect to Room: ${error.message}`);

        if (!this.state.hasRetry) {
          this.setState({ hasRetry: true }, this.connectToRoom);
          return;
        }

        this.setState({ isConnecting: false });
      }
    );
  };

  handleEndVisitPrompt = () => {
    this.handleToggleDialog(DIALOG_KEYS.IS_SHOW_CALL_DIALOG);
  };

  handleCompleteVisit = async () => {
    console.log('COMPLETING THE CALL!');
    const { visitId } = this.props.match.params;

    logEvent('end_visit', {
      visitId,
      providerId: this.props.providerId,
    });

    this.handleDisconnect();
    const response = await this.props.setVisitStatus(this.props.match.params.visitId, visitStatusTypes.COMPLETED);

    const isFacilitatedVisit = !isNil(this.props.selectedVisit.facility);
    await this.props.clearSelectedVisit();

    if (response.type === SET_VISIT_STATUS_SUCCESS) {
      // RMD-1778 only show confirm charges modal on non-facilitated visit
      // facilitated visits do not contain pricing information required for the modal
      if (!isFacilitatedVisit) {
        this.props.showConfirmChargesModal();
      }
      this.props.history.replace({
        pathname: `/previous/${this.props.match.params.visitId}`,
      });
    }

    this.handleToggleDialog(DIALOG_KEYS.IS_SHOW_CALL_DIALOG);
  };

  handleToggleDialog = dialogKey => this.setState(prevState => ({ [dialogKey]: !prevState[dialogKey] }));

  handleRetry = () => this.setState({ isConnecting: true }, this.connectToRoom);

  handleConvertVisitToVoice = async () => {
    const { selectedVisit, callPatient, setSelectedVisit } = this.props;

    const { type } = await callPatient(selectedVisit.id);

    if (type === CALL_PATIENT_SUCCESS) setSelectedVisit({ ...selectedVisit, isVoiceCall: true });

    this.handleToggleDialog(DIALOG_KEYS.IS_SHOW_CONVERT_TO_VOICE_DIALOG);
  };

  render() {
    const { classes, isBypassVisitPrompt, providerId, selectedVisit, videoConnectionStatus } = this.props;
    const { isConnected, isShowCallDialog, hasRetry, hasParticipants, isConnecting, isShowConvertToVoiceDialog } = this.state;

    const isVoiceCall = !isNil(selectedVisit) && selectedVisit.isVoiceCall;
    const voiceState = !isNil(selectedVisit) ? selectedVisit.voiceState : '';

    return (
      <>
        <Grid container>
          {(videoConnectionStatus === 'connecting' || videoConnectionStatus === 'connected') && (
            <Grid item xs={12} md={5}>
              <div
                ref={mediaSideDiv => {
                  this.mediaSideDiv = mediaSideDiv;
                }}
                className={classNames(classes.media, {
                  [classes.mediaSide]: !isConnected,
                  [classes.videoMediaSideConnected]: isConnected && !isVoiceCall,
                  [classes.mediaSideConnected]: isConnected,
                })}
              >
                <div className={classNames(classes.mediaContainer)}>
                  <div className={classNames(classes.videoContainer)}>
                    <div className={classNames(classes.participantMediaContainer)}>
                      <div className={classes.participantMediaWaiting}>Waiting for patient...</div>
                      <div id={MEDIA_IDS.PARTICIPANT_MEDIA} className={classes.participantMedia} />
                    </div>

                    <div className={classNames(classes.localMediaContainer)}>
                      <div id={MEDIA_IDS.LOCAL_MEDIA} className={classes.localMedia} />
                      {isConnected && (
                        <VisitCallControls
                          isVoiceCall={isVoiceCall}
                          providerId={providerId}
                          room={this.room}
                          onEndCall={() => this.handleToggleDialog(DIALOG_KEYS.IS_SHOW_CALL_DIALOG)}
                        />
                      )}
                    </div>

                    {(!isConnected || isVoiceCall) && (
                      <div className={classes.statusContainer}>
                        <VisitStatus
                          hasParticipants={hasParticipants}
                          hasRetry={hasRetry}
                          isConnected={isConnected}
                          isConnecting={isConnecting}
                          isVoiceCall={isVoiceCall}
                          voiceState={voiceState}
                          connectToRoom={this.handleRetry}
                          convertToVoice={() => this.handleToggleDialog(DIALOG_KEYS.IS_SHOW_CONVERT_TO_VOICE_DIALOG)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
          )}

          <Grid item xs={12} md={videoConnectionStatus === 'connecting' || videoConnectionStatus === 'connected' ? 7 : 12}>
            <ChartDetails isInVisit handleEndCall={() => this.setState({ isShowCallDialog: true })} />
            {isConnected && (
              <Prompt
                when={!isShowCallDialog && !isBypassVisitPrompt}
                message={() => `You are currently in a call. Are you sure you want to leave?`}
              />
            )}
          </Grid>
        </Grid>
        <CustomDialog
          content={<div>This will end the visit.</div>}
          open={isShowCallDialog}
          title="End visit?"
          handleClose={() => this.handleToggleDialog(DIALOG_KEYS.IS_SHOW_CALL_DIALOG)}
          handleAction={this.handleCompleteVisit}
        />
        <CustomDialog
          content={
            <Typography variant="subtitle1" id="modal-title">
              Are you sure you want to covert this visit to a voice call? This action cannot be undone.
            </Typography>
          }
          open={isShowConvertToVoiceDialog}
          title="Convert to Voice?"
          handleClose={() => this.handleToggleDialog(DIALOG_KEYS.IS_SHOW_CONVERT_TO_VOICE_DIALOG)}
          handleAction={this.handleConvertVisitToVoice}
        />
      </>
    );
  }
}

const styles = (/* theme */) => ({
  media: {
    width: 'calc(100% - 2rem)',
    height: '100%',
  },
  mediaSideConnected: {
    padding: '1rem',
    // position: 'fixed',
  },
  videoMediaSideConnected: {
    // marginLeft: '-1.5rem',
  },
  mediaSide: {
    padding: '1rem',
    position: 'relative',
  },
  statusContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: LOCAL_MEDIA_HEIGHT * 2,
    justifyContent: 'center',
    width: LARGE_MEDIA_WIDTH,
  },
  mediaContainer: {
    position: 'fixed',
    top: 'calc(134px + 1rem)',
    maxHeight: 'calc(100vh - 134px - 1rem)',
    overflow: 'auto',

    '@media (max-width: 1367px)': {
      maxWidth: 'calc(43vw - 1rem - 72px)',
    },

    '@media (max-width: 960px)': {
      position: 'static',
      maxWidth: '100%',
    },
  },
  participantMediaContainer: {
    position: 'relative',
    maxWidth: '100%',

    '@media (max-width: 960px)': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  participantMedia: {
    // float: 'right',
    overflow: 'auto',
    maxWidth: LARGE_MEDIA_WIDTH,
    minHeight: LARGE_MEDIA_WIDTH / 2,
    position: 'relative',
    top: 0,
    zIndex: 2,
    '& :before': {
      content: 'Waiting for patient...',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    '@media (max-width: 960px)': {
      '& div': {
        margin: '0 auto',
        display: 'inline-block',
      },
    },
  },
  participantMediaWaiting: {
    position: 'absolute',
  },
  localMediaContainer: {
    // position: 'fixed',
    // top: 'calc(134px + 384px + 2rem)',
    // width: '100%',
    '@media (max-width: 960px)': {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  },
  localMedia: {
    // left: '2rem',
    // position: 'absolute',
    // top: '2rem',
  },
  videoContainer: {},
  audioContainer: {},
});

VisitContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  match: ReactRouterPropTypes.match.isRequired,

  isBypassVisitPrompt: PropTypes.bool.isRequired,
  providerId: PropTypes.string.isRequired,
  selectedVisit: PropTypes.object,
  twilioVideoAuth: PropTypes.object,
  videoConnectionStatus: PropTypes.string,

  clearSelectedVisit: PropTypes.func.isRequired,
  callPatient: PropTypes.func.isRequired,
  getPreviousVisit: PropTypes.func.isRequired,
  getTwilioVideoAuth: PropTypes.func.isRequired,
  setBypassVisitPrompt: PropTypes.func.isRequired,
  setPageTitle: PropTypes.func.isRequired,
  setSelectedVisit: PropTypes.func.isRequired,
  setVisitStatus: PropTypes.func.isRequired,
  showConfirmChargesModal: PropTypes.func.isRequired,
  setVideoConnectionStatus: PropTypes.func.isRequired,
};

VisitContainer.defaultProps = {
  selectedVisit: null,
  twilioVideoAuth: null,
  videoConnectionStatus: 'disconnected',
};

const mapStateToProps = state => {
  return {
    isBypassVisitPrompt: state.layout.isBypassVisitPrompt,
    isLoading: state.visit.isLoading,
    providerId: state.provider.providerId,
    selectedVisit: state.layout.selectedVisit,
    twilioVideoAuth: state.visit.twilioVideoAuth,
    videoConnectionStatus: state.visit.videoConnectionStatus,
  };
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, {
    clearSelectedVisit: clearSelectedVisitAction,
    callPatient: callPatientAction,
    getPreviousVisit: getPreviousVisitAction,
    getTwilioVideoAuth: getTwilioVideoAuthAction,
    setBypassVisitPrompt: setBypassVisitPromptAction,
    setPageTitle: setPageTitleAction,
    setSelectedVisit: setSelectedVisitAction,
    setVisitStatus: setVisitStatusAction,
    showConfirmChargesModal: showConfirmChargesModalAction,
    setVideoConnectionStatus: setVideoConnectionStatusAction,
  })
)(VisitContainer);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Button, Grid, Typography } from '@material-ui/core';
import LocationIcon from '@material-ui/icons/LocationOn';
import { isNil } from 'lodash';
import CustomModal from '../../common/modal/customModal.component';
import CustomGoogleMap from '../../common/googleMap/customGoogleMap.component';
import { geocode, getAddressObject } from '../../utilities/locationUtils';
import { logEvent } from '../../utilities/googleAnalytics';
import { CHART_LAYOUT_BASE_ROW_HEIGHT } from '../../utilities/layoutUtils';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;

class ChartPatientLocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowLocationSelectMap: false,
      currentLocation: {},
      previousLocation: null,
    };

    this._isMounted = true;
  }

  componentDidMount() {
    if (this.props.location) {
      const { addressLine1, addressCity, addressState, addressZip } = this.props.location;
      const addressString = `${addressLine1},${addressCity},${addressState},${addressZip}`;

      geocode(addressString)
        .then(response => {
          if (response && response.ok === true && this._isMounted) {
            this.setState({
              currentLocation: {
                position: response.body.results[0].geometry.location,
                address: getAddressObject(response.body.results[0].address_components),
              },
            });
          }
        })
        .catch(err => {
          console.log(`Error geolocating address: ${addressString}`);
          console.log(err);
        });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleShowLocationSelectDialog = () => {
    logEvent('set_location', {
      providerId: this.props.providerId,
      priorLocation: this.state.currentLocation ? this.state.currentLocation : {},
    });

    // if a location is already selected, save it in case of cancel
    this.setState(prevState => ({
      isShowLocationSelectMap: true,
      previousLocation: prevState.currentLocation ? { ...prevState.currentLocation } : null, // copy
    }));
  };

  handleCloseLocationModal = () => {
    this.setState({
      isShowLocationSelectMap: false,
    });
  };

  handleUpdateLocation = location => {
    this.setState({
      currentLocation: {
        position: location.position,
        address: !isNil(location.place) ? getAddressObject(location.place.address_components) : location.address,
      },
    });
  };

  handleSaveLocation = (/* event */) => {
    this.handleCloseLocationModal();

    logEvent('updateaddress', {
      providerId: this.props.providerId,
      address: this.state.currentLocation.address,
    });

    this.props.handleFormUpdate({
      location: this.state.currentLocation.address,
    });
  };

  handleCancelSaveLocation = (/* event */) => {
    this.handleCloseLocationModal();

    this.setState(prevState => ({
      currentLocation: prevState.previousLocation ? { ...prevState.previousLocation } : null,
      previousLocation: null,
    }));
  };

  handleDeselectLocation = () => {
    this.setState({
      currentLocation: null,
    });
  };

  enterKeyListener = (target, hasLocation) => {
    if (target.charCode === 13) {
      if (hasLocation) {
        this.handleSaveLocation();
      }
    }
  };

  render() {
    const { isShowLocationSelectMap, currentLocation } = this.state;
    const { classes, theme, isReadOnly } = this.props;

    const hasLocation = Boolean(currentLocation);

    return (
      <div className={classes.padding}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.title}>CURRENT LOCATION:</Typography>
            </Grid>
            <CustomModal open={isShowLocationSelectMap} handleClose={this.handleCloseLocationModal}>
              <CustomGoogleMap
                googleMapURL={GOOGLE_MAP_URL}
                loadingElement={<div className={classes.modalMapsLoading} />}
                containerElement={<div className={classes.modalMapsContainer} />}
                mapElement={<div className={classes.modalMap} />}
                isSearchShown
                onUpdateLocation={this.handleUpdateLocation}
                mapOptions={{
                  streetViewControl: false,
                  mapTypeControl: false,
                  fullscreenControl: false,
                }}
                selectedMarker={hasLocation ? currentLocation : null}
                allowMultiMarkers
                handleAutoSubmit={this.handleSaveLocation}
              />
              <div className={classes.actionsContainer}>
                <Button variant="outlined" onClick={this.handleCancelSaveLocation} className={classes.mapActionButton} disableRipple>
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  onClick={this.handleSaveLocation}
                  className={classes.mapActionButton}
                  disableRipple
                  disabled={!hasLocation}
                  classes={{ disabled: classes.buttonDisabled }}
                >
                  Set Location
                </Button>
              </div>
            </CustomModal>
            {!currentLocation && (
              <Grid container className={classes.noLocation} alignItems="center" justify="center">
                <LocationIcon style={{ color: theme.palette.primary.darkgray, fontSize: '3rem' }} />
                <Typography>No Location Provided</Typography>
              </Grid>
            )}
            {currentLocation && (
              <CustomGoogleMap
                googleMapURL={GOOGLE_MAP_URL}
                loadingElement={<div className={classes.mapsLoading} />}
                containerElement={<div className={classes.mapsContainer} />}
                mapElement={<div className={classes.map} />}
                isSearchShown={false}
                mapOptions={{
                  streetViewControl: false,
                  mapTypeControl: false,
                  scaleControl: false,
                  zoomControl: false,
                  fullscreenControl: false,
                }}
                selectedMarker={currentLocation}
                allowMultiMarkers={false}
              />
            )}
            {!isReadOnly && (
              <Button
                variant="outlined"
                style={{
                  color: theme.palette.primary.main,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginTop: '1rem',
                  display: 'block',
                }}
                onClick={this.handleShowLocationSelectDialog}
                autoFocus={false}
                disableFocusRipple
                disableRipple
              >
                Set Location
              </Button>
            )}
          </Grid>
        </Paper>
      </div>
    );
  }
}

const styles = theme => ({
  padding: {
    padding: '1rem',
  },
  boxShadow: {
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
  mapActionButton: {
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.secondary.main,
    marginLeft: '1rem',
    '&:hover': {
      color: theme.palette.primary.white,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  buttonDisabled: {
    backgroundColor: theme.palette.primary.lightgray,
    '&:hover': {
      backgroundColor: theme.palette.secondary.lightgray,
    },
  },
  title: {
    paddingBottom: '0.5rem',
    paddingLeft: '0.5rem',
    whiteSpace: 'nowrap',
  },
  paper: {
    height: CHART_LAYOUT_BASE_ROW_HEIGHT,
    padding: '1rem 1rem 2rem',
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1rem',
    width: '100%',
  },
  modalMapsLoading: {
    height: '100%',
  },
  modalMapsContainer: {
    height: 500,
    width: 800,
  },
  modalMap: {
    height: 500,
    width: 800,
  },
  mapsLoading: {
    height: '100%',
  },
  mapsContainer: {
    height: '12rem',
    width: '100%',
  },
  map: {
    height: '12rem',
    width: '100%',
  },
  noLocation: {
    backgroundColor: theme.palette.primary.lightgray,
    borderRadius: '10px',
    flexDirection: 'column',
    height: '12rem',
    width: '100%',
  },
});

ChartPatientLocation.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,

  isReadOnly: PropTypes.bool,
  location: PropTypes.object.isRequired,
  providerId: PropTypes.string.isRequired,

  handleFormUpdate: PropTypes.func.isRequired,
};

ChartPatientLocation.defaultProps = {
  isReadOnly: false,
};

export default withStyles(styles, { withTheme: true })(ChartPatientLocation);

import { CALL_API } from '../../middleware/api';

export const GET_BOARD_ITEMS_REQUEST = 'GET_BOARD_ITEMS_REQUEST';
export const GET_BOARD_ITEMS_SUCCESS = 'GET_BOARD_ITEMS_SUCCESS';
export const GET_BOARD_ITEMS_FAILURE = 'GET_BOARD_ITEMS_FAILURE';

export const GET_NEW_BOARD_ITEMS_REQUEST = 'GET_NEW_BOARD_ITEMS_REQUEST';
export const GET_NEW_BOARD_ITEMS_SUCCESS = 'GET_NEW_BOARD_ITEMS_SUCCESS';
export const GET_NEW_BOARD_ITEMS_FAILURE = 'GET_NEW_BOARD_ITEMS_FAILURE';

export const GET_ACTIVE_BOARD_ITEMS_REQUEST = 'GET_ACTIVE_BOARD_ITEMS_REQUEST';
export const GET_ACTIVE_BOARD_ITEMS_SUCCESS = 'GET_ACTIVE_BOARD_ITEMS_SUCCESS';
export const GET_ACTIVE_BOARD_ITEMS_FAILURE = 'GET_ACTIVE_BOARD_ITEMS_FAILURE';

export const GET_ASSIGNED_BOARD_ITEMS_REQUEST = 'GET_ASSIGNED_BOARD_ITEMS_REQUEST';
export const GET_ASSIGNED_BOARD_ITEMS_SUCCESS = 'GET_ASSIGNED_BOARD_ITEMS_SUCCESS';
export const GET_ASSIGNED_BOARD_ITEMS_FAILURE = 'GET_ASSIGNED_BOARD_ITEMS_FAILURE';

export const SET_VISIT_STATUS_REQUEST = 'SET_VISIT_STATUS_REQUEST';
export const SET_VISIT_STATUS_SUCCESS = 'SET_VISIT_STATUS_SUCCESS';
export const SET_VISIT_STATUS_FAILURE = 'SET_VISIT_STATUS_FAILURE';

export const UPDATE_BOARD_VISIT_STATUS = 'UPDATE_BOARD_VISIT_STATUS';

export const getBoardItems = isDisableLoading => {
  return {
    [CALL_API]: {
      types: [GET_BOARD_ITEMS_REQUEST, GET_BOARD_ITEMS_SUCCESS, GET_BOARD_ITEMS_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/board`,
      method: 'GET',
      actionMetadata: { isDisableLoading },
    },
  };
};

export const getNewBoardItems = isDisableLoading => {
  return {
    [CALL_API]: {
      types: [GET_NEW_BOARD_ITEMS_REQUEST, GET_NEW_BOARD_ITEMS_SUCCESS, GET_NEW_BOARD_ITEMS_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/board/new`,
      method: 'GET',
      actionMetadata: { isDisableLoading },
    },
  };
};

export const getAssignedBoardItems = isDisableLoading => {
  return {
    [CALL_API]: {
      types: [GET_ASSIGNED_BOARD_ITEMS_REQUEST, GET_ASSIGNED_BOARD_ITEMS_SUCCESS, GET_ASSIGNED_BOARD_ITEMS_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/board/assigned`,
      method: 'GET',
      actionMetadata: { isDisableLoading },
    },
  };
};

export const getActiveBoardItems = isDisableLoading => {
  return {
    [CALL_API]: {
      types: [GET_ACTIVE_BOARD_ITEMS_REQUEST, GET_ACTIVE_BOARD_ITEMS_SUCCESS, GET_ACTIVE_BOARD_ITEMS_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/board/active`,
      method: 'GET',
      actionMetadata: { isDisableLoading },
    },
  };
};

export const setVisitStatus = (visitId, state, unreachable = false) => {
  return {
    [CALL_API]: {
      types: [SET_VISIT_STATUS_REQUEST, SET_VISIT_STATUS_SUCCESS, SET_VISIT_STATUS_FAILURE],
      authenticated: true,
      endpoint: `v1/provider/visits/${visitId}/state`,
      method: 'POST',
      payload: {
        state,
        unreachable,
      },
    },
  };
};

export const updateBoardVisitStatus = payload => {
  return {
    type: UPDATE_BOARD_VISIT_STATUS,
    payload,
  };
};

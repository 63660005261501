import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, withStyles } from '@material-ui/core';
import { MicOff, Mic, VideocamOff, Videocam, Close } from '@material-ui/icons';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { logEvent } from '../../utilities/googleAnalytics';
import VisitUtils from '../../utilities/visitUtils';

const VisitCallControls = ({ classes, isVoiceCall, providerId, room, onEndCall }) => {
  const { visitId } = useParams();

  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(false);

  const toggleAudioMute = useCallback(() => {
    logEvent('toggle_mute', {
      visitId,
      providerId,
      muteState: !isAudioMuted,
    });

    VisitUtils.toggleAudioMute(room, !isAudioMuted);
    setIsAudioMuted(!isAudioMuted);
  }, [isAudioMuted, providerId, room, visitId, setIsAudioMuted]);

  const toggleVideoMute = useCallback(() => {
    logEvent('toggle_video', {
      visitId,
      providerId,
      videoState: !isVideoMuted,
    });

    VisitUtils.toggleVideoMute(room, !isVideoMuted);
    setIsVideoMuted(!isVideoMuted);
  }, [isVideoMuted, providerId, room, visitId, setIsVideoMuted]);

  return (
    <div className={classNames(classes.actionsContainer, classes.videoActionsContainer)}>
      <IconButton classes={{ root: classes.videoActionButton }} aria-label="Mute" color="inherit" onClick={toggleAudioMute}>
        {isAudioMuted ? <MicOff /> : <Mic />}
      </IconButton>
      {!isVoiceCall && (
        <IconButton classes={{ root: classes.videoActionButton }} aria-label="Mute Video" color="inherit" onClick={toggleVideoMute}>
          {isVideoMuted ? <VideocamOff /> : <Videocam />}
        </IconButton>
      )}
      {/*<IconButton classes={{ root: classes.videoActionButton }} color="inherit" onClick={onEndCall}>*/}
      {/*  <Close />*/}
      {/*</IconButton>*/}
    </div>
  );
};

const styles = theme => ({
  actionsContainer: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#F5F7FA',
  },
  videoActionsContainer: {
    // justifyContent: 'flex-end',
    // position: 'absolute',
    // right: 12,
    // top: 24,
    '@media (max-width: 960px)': {
      justifyContent: 'center',
    },
  },
  videoActionButton: {
    color: theme.palette.primary.white,
    backgroundColor: theme.palette.primary.darkgray,
    marginRight: '1rem',
    '&:hover': {
      color: theme.palette.primary.white,
      backgroundColor: theme.palette.primary.darkgray,
    },
  },
  audioActionsContainer: {
    justifyContent: 'space-between',
    marginTop: theme.spacing.unit * 4,
  },
});

VisitCallControls.propTypes = {
  classes: PropTypes.object.isRequired,

  isVoiceCall: PropTypes.bool.isRequired,
  providerId: PropTypes.string.isRequired,
  room: PropTypes.object.isRequired,

  onEndCall: PropTypes.func.isRequired,
};

export default withStyles(styles)(VisitCallControls);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography, Table, TableRow, TableCell, TableBody } from '@material-ui/core';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import ReactRouterPropTypes from 'react-router-prop-types';
import PreviousVisitSummaryModal from '../previousVisits/previousVisitSummary/previousVisitSummaryModal.component';
import { mapJsonToLabelList } from '../../utilities/nihApiUtils';
import { nihApiTypes } from '../../types/nihApiTypes';
import { GET_PREVIOUS_VISIT_SUCCESS, getPreviousVisit } from './chart.actions';
import { CHART_LAYOUT_BASE_ROW_HEIGHT } from '../../utilities/layoutUtils';

class ChartPatientPreviousVisits extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowVisitSummaryModal: false,
      currentVisit: {},
      visitPaging: {
        current: 0,
        total: 0,
      },
    };
  }

  handleRowClick = async (rowData, index) => {
    const { visitId } = rowData;
    const response = await this.props.getPreviousVisit(visitId);

    if (response.type === GET_PREVIOUS_VISIT_SUCCESS) {
      this.setState({
        isShowVisitSummaryModal: true,
        currentVisit: response.response,
        visitPaging: {
          total: this.props.previousVisits.length,
          current: index,
        },
      });
    }
  };

  handleCloseVisitSummaryModal = () => {
    this.setState({
      isShowVisitSummaryModal: false,
      currentVisit: {},
    });
  };

  handleRowEdit = id => {
    this.props.history.push(`/previous/${id}`);
  };

  handleSetCurrentVisit = async index => {
    const newVisit = this.props.previousVisits[index];

    const { visitId } = newVisit;
    const response = await this.props.getPreviousVisit(visitId);

    if (response.type === GET_PREVIOUS_VISIT_SUCCESS) {
      this.setState(prevState => ({
        isShowVisitSummaryModal: true,
        currentVisit: response.response,
        visitPaging: {
          ...prevState.visitPaging,
          current: index,
        },
      }));
    }
  };

  renderDescription = visit => {
    if (!isEmpty(visit.diagnosis)) {
      return mapJsonToLabelList(nihApiTypes.NIH_DIAGNOSIS, visit.diagnosis);
    }

    return visit.chiefComplaint ? visit.chiefComplaint : 'Diagnosis: Unknown';
  };

  render() {
    const { classes, previousVisits, isEditEnabled, noteTypes } = this.props;
    const { isShowVisitSummaryModal, currentVisit, visitPaging } = this.state;

    return (
      <div className={classes.padding}>
        <Paper className={classes.paper}>
          {previousVisits.length > 0 && <Typography className={classes.title}>PREVIOUS VISITS:</Typography>}
          {previousVisits.length <= 0 && <Typography className={classes.title}>NO PREVIOUS VISITS</Typography>}
          <Table style={{ tableLayout: 'auto' }}>
            <TableBody>
              {previousVisits.map((visit, i) => (
                <TableRow key={visit.visitId} classes={{ root: classes.tableRowRoot }} onClick={() => this.handleRowClick(visit, i)}>
                  <TableCell style={{ width: '25%' }}>
                    <Typography>{moment(visit.created).format('M/D/YYYY')}</Typography>
                  </TableCell>
                  <TableCell style={{ width: '75%' }}>
                    <Typography>{this.renderDescription(visit)}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <PreviousVisitSummaryModal
          open={isShowVisitSummaryModal}
          handleClose={this.handleCloseVisitSummaryModal}
          visit={currentVisit}
          handleEdit={isEditEnabled ? this.handleRowEdit : null}
          visitPaging={visitPaging}
          handleSetCurrentVisit={this.handleSetCurrentVisit}
          noteTypes={noteTypes}
        />
      </div>
    );
  }
}

const styles = theme => ({
  padding: {
    padding: '1rem',
  },
  tableRowRoot: {
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.primary.lightgray,
    },
    height: '1rem',
    cursor: 'pointer',
  },
  paper: {
    height: CHART_LAYOUT_BASE_ROW_HEIGHT,
    overflow: 'auto',
    padding: '1rem 1rem 2rem 1rem',
  },
  title: {
    marginBottom: '0.5rem',
  },
});

ChartPatientPreviousVisits.propTypes = {
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,

  isEditEnabled: PropTypes.bool,
  noteTypes: PropTypes.array.isRequired,
  previousVisits: PropTypes.array.isRequired,

  getPreviousVisit: PropTypes.func.isRequired,
};

ChartPatientPreviousVisits.defaultProps = {
  isEditEnabled: false,
};

const mapStateToProps = state => {
  return {
    noteTypes: state.chart.noteTypes,
  };
};

export default withStyles(styles)(
  withRouter(
    connect(mapStateToProps, {
      getPreviousVisit,
    })(ChartPatientPreviousVisits)
  )
);

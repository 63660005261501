import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Icon, Tooltip, Typography, Avatar } from '@material-ui/core';
import ReactSelectField from '../../common/reactSelectField/reactSelectField.component';
import { nihApiTypes } from '../../types/nihApiTypes';
import { fetchData } from '../../common/api/api.actions';
import { GET_API_DATA_SUCCESS } from '../../common/api/api.actions';
import { searchAllergies, SEARCH_ALLERGIES_SUCCESS } from '../chart/chart.actions';
import { getProxyApiPath, mapNihDataToSelectOptions, mapJsonArrayToSelectOptions } from '../../utilities/nihApiUtils';
import { mapAllergyJsonToSelectOptions } from '../../utilities/dataUtils';
import { medicalHistoryTypes } from '../../types/medicalHistoryTypes';
import clsx from 'clsx';
import { can } from '../login/can';
import { providerGroupPermissions } from '../../types/permissionTypes';
import { logEvent } from '../../utilities/googleAnalytics';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  formContainer: {
    padding: '1rem',
  },
  formLabel: {
    marginRight: '0.5rem',
    marginTop: '1rem',
  },
  formField: {
    display: 'flex',
    minHeight: 50,
  },
  icon: {
    fontSize: 18,
    color: theme.palette.primary.main,
    marginTop: -2,
    marginLeft: 2,
    marginRight: 2,
  },
  tooltipContent: {
    fontSize: '0.8rem',
    color: theme.palette.primary.white,
  },
  rxIcon: {
    fontSize: 17,
    marginLeft: 5,
  },
  rxIconAvatar: {
    backgroundColor: theme.palette.primary.lightgray,
    color: theme.palette.secondary.contrastText,
    height: 24,
    width: 24,
    marginRight: 4,
  },
  textField: {
    width: '100%',
  },
});

class ChartPatientHistoryForm extends Component {
  handleSelectFieldChange = (fieldName, values) => {
    const updateValues = {};
    updateValues[fieldName] = values;

    this.logGAEvent(fieldName);

    if (this.props.handleFormUpdate) this.props.handleFormUpdate(updateValues);
  };

  logGAEvent = type => {
    let eventName;
    switch (type) {
      case 'medicalHistory':
        eventName = 'Update_MHX';
        break;
      case 'surgicalHistory':
        eventName = 'Update_SHx';
        break;
      case 'medications':
        eventName = 'Update_meds';
        break;
      case 'allergies':
        eventName = 'Update_Allergies';
        break;

      default:
        eventName = 'Update_Unknown_Med_History';
    }

    logEvent(eventName, {
      providerId: this.props.providerId,
    });
  };

  handleFetchData = type => async query => {
    if (type === medicalHistoryTypes.TYPE_ALLERGIES) {
      const response = await this.props.searchAllergies(query);
      if (response.type === SEARCH_ALLERGIES_SUCCESS) {
        return mapAllergyJsonToSelectOptions(response.response);
      }
    } else {
      let response = await this.props.fetchData(getProxyApiPath(type, query));
      if (response.type === GET_API_DATA_SUCCESS) {
        return mapNihDataToSelectOptions(type, response.response);
      }
    }
  };

  render() {
    const { classes, isReadOnly, visit } = this.props;

    const tooltipContent = (
      <Typography className={classes.tooltipContent}>
        {'RX icons demote a medication was matched in the drug database and can be used for interaction checking'}
      </Typography>
    );

    const rxIcon = (
      <Avatar className={classes.rxIconAvatar}>
        <Icon className={clsx(classes.rxIcon, 'fal fa-prescription')} />
      </Avatar>
    );

    return (
      <Grid container className={classes.formContainer}>
        <Grid item xs={12} sm={4} className={classes.formField}>
          <div className={classes.formLabel}>{'Weight'}</div>
          <div style={{ flex: '1 1 auto' }}>
            <TextField value={visit.weight} className={classes.textField} readOnly disabled />
          </div>
        </Grid>
        <Grid item xs={12} sm={4} className={classes.formField}>
          <div className={classes.formLabel}>{'Height (ft)'}</div>
          <div style={{ flex: '1 1 auto' }}>
            <TextField value={visit.heightFeet} className={classes.textField} readOnly disabled />
          </div>
        </Grid>
        <Grid item xs={12} sm={4} className={classes.formField}>
          <div className={classes.formLabel}>{'Height (in)'}</div>
          <div style={{ flex: '1 1 auto' }}>
            <TextField value={visit.heightInches} className={classes.textField} readOnly disabled />
          </div>
        </Grid>

        {can(providerGroupPermissions.readMHx) && (
          <Grid item xs={12} className={classes.formField}>
            <div className={classes.formLabel}>{'MHx:'}</div>
            <div style={{ flex: '1 1 auto' }}>
              <ReactSelectField
                selectedValues={visit.medicalHistory ? mapJsonArrayToSelectOptions(nihApiTypes.NIH_CONDITIONS, visit.medicalHistory) : []}
                handleChange={value => this.handleSelectFieldChange('medicalHistory', value)}
                isMulti={true}
                isReadOnly={isReadOnly || !can(providerGroupPermissions.updateMHx)}
                handleFetchData={this.handleFetchData(nihApiTypes.NIH_CONDITIONS)}
              />
            </div>
          </Grid>
        )}
        {can(providerGroupPermissions.readSHx) && (
          <Grid item xs={12} className={classes.formField}>
            <div className={classes.formLabel}>{'SHx:'}</div>
            <div style={{ flex: '1 1 auto' }}>
              <ReactSelectField
                selectedValues={visit.surgicalHistory ? mapJsonArrayToSelectOptions(nihApiTypes.NIH_PROCEDURES, visit.surgicalHistory) : []}
                handleChange={value => this.handleSelectFieldChange('surgicalHistory', value)}
                isMulti={true}
                isReadOnly={isReadOnly || !can(providerGroupPermissions.updateSHx)}
                handleFetchData={this.handleFetchData(nihApiTypes.NIH_PROCEDURES)}
              />
            </div>
          </Grid>
        )}
        {can(providerGroupPermissions.readMedication) && (
          <Grid item xs={12} className={classes.formField}>
            <div className={classes.formLabel} style={{ display: 'flex' }}>
              {'Medications'}
              <Tooltip title={tooltipContent}>
                <Icon className={clsx(classes.icon, 'fal fa-info-circle')} />
              </Tooltip>
              {':'}
            </div>

            <div style={{ flex: '1 1 auto' }}>
              <ReactSelectField
                selectedValues={visit.medications ? mapJsonArrayToSelectOptions(nihApiTypes.NIH_RXTERMS, visit.medications) : []}
                handleChange={value => this.handleSelectFieldChange('medications', value)}
                isMulti={true}
                isReadOnly={isReadOnly || !can(providerGroupPermissions.updateMedication)}
                handleFetchData={this.handleFetchData(nihApiTypes.NIH_RXTERMS)}
                rxIcon={rxIcon}
              />
            </div>
          </Grid>
        )}
        {can(providerGroupPermissions.readAllergy) && (
          <Grid item xs={12} className={classes.formField}>
            <div className={classes.formLabel} style={{ display: 'flex' }}>
              {'Allergies'}
              <Tooltip title={tooltipContent}>
                <Icon className={clsx(classes.icon, 'fal fa-info-circle')} />
              </Tooltip>
              {':'}
            </div>
            <div style={{ flex: '1 1 auto' }}>
              <ReactSelectField
                selectedValues={visit.allergies ? mapAllergyJsonToSelectOptions(visit.allergies) : []}
                handleChange={value => this.handleSelectFieldChange('allergies', value)}
                isMulti={true}
                isReadOnly={isReadOnly || !can(providerGroupPermissions.updateAllergy)}
                handleFetchData={this.handleFetchData(medicalHistoryTypes.TYPE_ALLERGIES)}
                rxIcon={rxIcon}
              />
            </div>
          </Grid>
        )}
      </Grid>
    );
  }
}

ChartPatientHistoryForm.defaultProps = {
  isReadOnly: false,
};

ChartPatientHistoryForm.propTypes = {
  classes: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool,
  visit: PropTypes.object,
  handleFormUpdate: PropTypes.func,
  providerId: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    providerId: state.provider.providerId,
  };
};

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, {
    fetchData,
    searchAllergies,
  })(ChartPatientHistoryForm)
);

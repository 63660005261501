import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Lists from '@convertkit/slate-lists';
import { Toolbar } from '@material-ui/core';
import { Editor } from 'slate-react';
import Plain from 'slate-plain-serializer';
import markPlugin from './components/markPlugin';
import ToolbarButton from './components/toolbarButton';
import blockPlugin from './components/blockPlugin';
import variablePlugin from './components/variablesPlugin';
import VariableSelect from './components/variableSelect';
import { generateGuid } from '../../utilities/guid';

export const DEFAULT_BLOCK = 'PARAGRAPH';

const styles = theme => ({
  toolbarRoot: {
    minHeight: 30,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  editorContainer: {
    border: `solid 2px ${theme.palette.primary.lightgray}`,
    minHeight: '1rem',
    padding: '1rem',
    maxWidth: 1200
  },
  toolbarIconRoot: {
    fontSize: '0.75rem'
  },
  toolbarButtonActive: {
    width: 30,
    minWidth: 0,
    color: theme.palette.primary.main
  },
  toolbarButtonInactive: {
    width: 30,
    minWidth: 0,
    color: theme.palette.primary.darkgray
  },
  fullWidth: {
    width: '100%'
  }
});

const marks = {
  bold: {
    markType: 'bold',
    WrapperType: 'strong',
    hotKey: 'mod+b',
    command: 'toggleBold',
    activeQuery: 'isBold',
    icon: 'format_bold'
  },
  underlined: {
    markType: 'underlined',
    WrapperType: 'u',
    hotKey: 'mod+u',
    command: 'toggleUnderlined',
    activeQuery: 'isUnderlined',
    icon: 'format_underlined'
  },
  italic: {
    markType: 'italic',
    WrapperType: 'em',
    hotKey: 'mod+i',
    command: 'toggleItalic',
    activeQuery: 'isItalic',
    icon: 'format_italic'
  }
};

const blocks = {
  h1: {
    blockType: 'H1',
    WrapperType: 'h1',
    command: 'toggleH1',
    activeQuery: 'isH1',
    hotKey: 'Control+1',
    exitOnEnter: true,
    icon: 'looks_one'
  },
  h2: {
    blockType: 'H2',
    WrapperType: 'h2',
    command: 'toggleH2',
    activeQuery: 'isH2',
    hotKey: 'Control+2',
    icon: 'looks_two'
  }
};

const listButtons = {
  ordered: {
    icon: 'format_list_numbered',
    command: 'toggleList',
    commandArgs: { type: 'ordered-list' }
  },
  unordered: { icon: 'format_list_bulleted', command: 'toggleList' },
  increaseListItemDepth: { icon: 'format_indent_increase', command: 'increaseListItemDepth' },
  decreaseListItemDepth: { icon: 'format_indent_decrease', command: 'decreaseListItemDepth' }
};

// debounce onChange when typing
let debounceTimer;
class CustomRichTextEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.initialValue
    };

    this.pluginList = [
      Object.keys(marks).map(m => markPlugin(marks[m])),
      Object.keys(blocks).map(b => blockPlugin({ ...blocks[b], defaultBlock: DEFAULT_BLOCK })),
      Lists()
    ];
    if (props.templateVariables) this.pluginList.push(variablePlugin({ variables: props.templateVariables, memorizeOnLoad: true }));
  }

  componentWillReceiveProps(nextProps) {
    clearTimeout(debounceTimer); // cancel any pending onChange
    this.setValue(nextProps.initialValue);
  }

  setValue = value => {
    this.setState({
      value
    });
  };

  handleChange = ({ value }) => {
    this.setValue(value);

    // debounce callback for performance
    if (this.props.onChange) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => this.props.onChange(value), 200);
    }
  };

  renderButton = ({ icon, text, activeQuery, command, commandArgs }) => {
    const isActive = activeQuery ? this.editorRef && this.editorRef.query(activeQuery) : false;

    return (
      <ToolbarButton
        icon={icon}
        classes={{
          root: isActive ? this.props.classes.toolbarButtonActive : this.props.classes.toolbarButtonInactive
        }}
        onMouseDown={() => {
          this.editorRef.command(command, commandArgs);
          //TODO: refocus in editor
        }}
        text={text}
        key={generateGuid()}
      />
    );
  };

  render() {
    const { classes, isReadOnly, templateVariables } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.fullWidth}>
        {!isReadOnly && (
          <Toolbar classes={{ root: classes.toolbarRoot }}>
            {templateVariables && (
              <div style={{ marginRight: '0.5rem' }}>
                <VariableSelect editor={this.editorRef} memorize />
                {' || '}
              </div>
            )}
            {this.renderButton(marks.bold)}
            {this.renderButton(marks.italic)}
            {this.renderButton(marks.underlined)}
            {' || '}
            {this.renderButton(blocks.h1)}
            {this.renderButton(blocks.h2)}
            {' || '}
            {this.renderButton(listButtons.unordered)}
            {this.renderButton(listButtons.ordered)}
            {this.renderButton(listButtons.increaseListItemDepth)}
            {this.renderButton(listButtons.decreaseListItemDepth)}
          </Toolbar>
        )}
        <div className={classes.editorContainer}>
          <Editor
            readOnly={isReadOnly}
            plugins={this.pluginList}
            value={value}
            onChange={this.handleChange}
            ref={ref => (this.editorRef = ref)}
            spellCheck
            placeholder={'Enter a note...'}
            autoFocus={!isReadOnly}
          />
        </div>
      </div>
    );
  }
}

CustomRichTextEditor.defaultProps = {
  isReadOnly: false,
  initialValue: Plain.deserialize('')
};

CustomRichTextEditor.propTypes = {
  classes: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool,
  initialValue: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  templateVariables: PropTypes.object
};

export default withStyles(styles)(CustomRichTextEditor);

const Colors = {
  primary: {
    main: '#2B82D8',
    gray: '#777',
    darkgray: '#5C5C5C',
    contrastText: '#fff',
    background: '#EEEEEE',
    lightgray: '#E0E0E0',
    white: '#FFFFFF',
    orange: '#F2A11E',
    lightOrange: '#F2A11E',
    red: '#C0392B',
    green: '#5DFF25',
    lightBlue: '#E3EEF9',
  },
  secondary: {
    light: 'rgb(238, 238, 238)',
    main: '#06006D',
    dark: '#ba000d',
    contrastText: '#000',
  },
};

export default Colors;

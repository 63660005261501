import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, FormControlLabel, Icon } from '@material-ui/core';
import CustomRichTextEditor from '../../common/richTextEditor/customRichTextEditor.component';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import { Value } from 'slate';
import CustomDialog from '../../common/customDialog/customDialog.component';
import ChartPatientNoteTemplateForm from './chartPatientNoteTemplateForm.component';
import CustomModal from '../../common/modal/customModal.component';
import { find, cloneDeep, isNil } from 'lodash';
import { ADD_CHART_NOTE_SUCCESS, setNoteVisibility, SET_NOTE_VISIBILITY_SUCCESS } from './chart.actions';
import Checkbox from '@material-ui/core/Checkbox';
import { getAgeString } from '../../utilities/dataUtils';
import { getPatientPossessivePronoun } from '../../types/genderTypes';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { showToast } from '../layout/layout.actions';
import { can } from '../login/can';
import { providerGroupPermissions } from '../../types/permissionTypes';
import { logEvent } from '../../utilities/googleAnalytics';

const styles = theme => ({
  heading: {
    fontSize: '0.9rem',
    alignSelf: 'center',
    marginLeft: '0.5rem',
    display: 'flex',
    fontWeight: theme.typography.fontWeightMedium
  },
  subHeading: {
    fontWeight: theme.typography.fontWeight
  },
  noteActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  expansionPanel: {
    margin: '0.5rem'
  },
  expansionPanelDetailRoot: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    marginBottom: '0.5rem'
  },
  expansionPanelFooter: {
    display: 'flex',
    alignItems: 'center',
    padding: '1rem'
  },
  boxShadow: {
    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 4px 4px 0 rgba(0, 0, 0, 0.19)'
  },
  formControlDisabled: {
    color: theme.palette.primary.darkgray + ' !important'
  },
  editIcon: {
    fontSize: '14px',
    alignSelf: 'center',
    marginLeft: '0.5rem'
  }
});

class ChartPatientAddendums extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: null,
      notes: props.notes,
      isShowDeleteNoteDialog: false,
      noteToDelete: null,
      isShowAddNoteDialog: false
    };

    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      notes: nextProps.notes
    });
  }

  handleExpandPanel = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  // convert a json string into a useful rich text object
  getInitialTextEditorValue = body => {
    return typeof body === 'string' ? Value.fromJSON(JSON.parse(body)) : body;
  };

  handleCreateNote = async (noteTypeId, templateId, visibleToPatient) => {
    this.setState({
      isShowAddNoteDialog: false
    });

    let noteType = find(this.props.noteTypes, { id: noteTypeId });
    let template = find(noteType.templates, { id: templateId });

    let addChartNoteResponse = await this.props.addChartNote(
      this.props.visitId,
      {
        body: template.body,
        typeId: noteTypeId,
        visibleToPatient
      },
      {
        providerId: this.props.providerId
      }
    );

    if (addChartNoteResponse.type === ADD_CHART_NOTE_SUCCESS) {
      logEvent('Addend_Notes', {
        providerId: this.props.providerId,
        primaryProviderId: this.props.primaryProviderId
      });

      this.setState({
        expanded: addChartNoteResponse.id
      });
    }
  };

  handleCloseAddNoteDialog = () => {
    this.setState({
      isShowAddNoteDialog: false
    });
  };

  handleShowDeleteDialog = id => event => {
    this.setState({
      isShowDeleteNoteDialog: true,
      noteToDelete: id
    });
  };

  handleChangeNoteBody = id => value => {
    if (this._isMounted) {
      let updateNotes = [...this.state.notes];
      let index = updateNotes.findIndex(note => note.id === id);
      if (index > -1) {
        updateNotes[index].body = value;
        updateNotes[index].isDirty = true;
        this.setState({
          notes: updateNotes
        });
        if (!isNil(this.props.handleNotesUpdate)) {
          this.props.handleNotesUpdate(updateNotes);
        }
      }
    }
  };

  handleToggleShare = id => async evt => {
    if (evt) {
      evt.stopPropagation();
      evt.preventDefault();
    }

    let updateNotes = [...this.state.notes];
    let originalNotes = cloneDeep(updateNotes);

    let index = updateNotes.findIndex(note => note.id === id);

    const newValue = !updateNotes[index].visibleToPatient;
    updateNotes[index].visibleToPatient = newValue;
    this.setState({
      notes: updateNotes
    });

    if (!isNil(this.props.handleNotesUpdate)) {
      this.props.handleNotesUpdate(updateNotes);
    }

    // toggle visibility on server
    // revert change if failed
    let visibilityResponse = await this.props.setNoteVisibility(this.props.visitId, id, newValue);
    if (visibilityResponse.type !== SET_NOTE_VISIBILITY_SUCCESS) {
      this.props.showToast('Could not update note visibility. Please check your connection and try again.');
      this.setState({
        notes: originalNotes
      });
      if (!isNil(this.props.handleNotesUpdate)) {
        this.props.handleNotesUpdate(originalNotes);
      }
    }
  };

  handleCloseDeleteDialog = () => {
    this.setState({
      isShowDeleteNoteDialog: false,
      noteToDelete: null
    });
  };

  handleDeleteNote = id => {
    this.setState({
      isShowDeleteNoteDialog: false,
      noteToDelete: null
    });
    this.props.deleteChartNote(this.props.visitId, id);
  };

  handleDiscardChanges = id => {
    this.props.handleDiscardNoteChanges(id);
  };

  handleShowAddNoteModal = () => {
    this.setState({
      isShowAddNoteDialog: true
    });
  };

  // handle updating order of notes in state
  moveNote = (dragIndex, hoverIndex) => {
    const dragNote = this.state.notes[dragIndex];
    let updateNotes = [...this.state.notes];
    updateNotes.splice(dragIndex, 1);
    updateNotes.splice(hoverIndex, 0, dragNote);
    this.setState({
      notes: updateNotes
    });

    // lift state
    this.props.handleNotesUpdate(updateNotes);
  };

  getNoteTypeText = typeId => {
    const { noteTypes } = this.props;
    let foundType = noteTypes.find(type => type.id === typeId);
    return foundType ? foundType.name : 'Note'; // if no name found, default text
  };

  render() {
    const {
      classes,
      noteTypes,
      isReadOnly,
      theme,
      providerId,
      visitDate,
      providerName,
      entranceBrandName,
      patientName,
      patientDob,
      patientGender
    } = this.props;

    const { expanded, isShowDeleteNoteDialog, noteToDelete, isShowAddNoteDialog } = this.state;
    let { notes } = this.state;

    const editorTemplateVariables = {};
    if (visitDate) editorTemplateVariables.visitDate = moment(visitDate).format('l');
    if (providerName) editorTemplateVariables.providerName = providerName;
    if (entranceBrandName) editorTemplateVariables.entranceBrandName = entranceBrandName;
    if (patientName) editorTemplateVariables.patientName = patientName;
    if (patientDob) {
      editorTemplateVariables.patientAge = getAgeString(patientDob);
      editorTemplateVariables.patientDob = patientDob;
    }
    if (patientGender) {
      editorTemplateVariables.patientGender = patientGender;
      editorTemplateVariables.patientPossessivePronoun = getPatientPossessivePronoun(patientGender);
    }

    // sort addendums
    // 1) by earliest signed date (asc)
    // if no signedDate,
    // 2) by earliest create date (asc)
    notes = notes.sort((a, b) => {
      return moment(a.signedDate) - moment(b.signedDate) || moment(a.created) - moment(b.created);
    });

    return (
      // overflow auto required for proper drag/drop behavior
      <div className={classes.boxShadow} style={{ padding: '1rem', overflow: 'auto', margin: '1rem', borderRadius: 5 }}>
        <div style={{ paddingBottom: '1rem' }}>
          <Typography className={classes.padding}>{'ADDENDUMS:'}</Typography>
          {notes.length <= 0 ? (
            <div style={{ padding: '1rem' }}>{'No Addendums Available.'}</div>
          ) : (
              notes.map((note, i) => {
                const initialValue = this.getInitialTextEditorValue(note.body);
                const noteTypeText = note.type ? note.type : this.getNoteTypeText(note.typeId);

                let lastSigned;
                if (note.signedDate) {
                  lastSigned = ` - Last signed ${moment(note.signedDate).format('M/D/YYYY HH:mm')} by ${note.providerName}`;
                }

                if (note.isDirty === true && note.signedDate) lastSigned += ' (Modified)';

                const isSigned = !!note.signedDate; // disallow edits on signed addendums

                // READ ONLY NOTE LIST
                if (isReadOnly === true) {
                  return (
                    <ExpansionPanel
                      expanded={expanded === note.id}
                      onChange={this.handleExpandPanel(note.id)}
                      className={classes.expansionPanel}
                      key={note.id}
                    >
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <div style={{ display: 'flex', flex: '1 1 auto' }}>
                          <Typography className={classes.heading} justify={'center'} align={'left'} component={'div'}>
                            {noteTypeText}
                            <Typography className={classes.subHeading}>{lastSigned}</Typography>
                          </Typography>
                        </div>
                        {expanded !== note.id && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={note.visibleToPatient}
                                value="visibleToPatient"
                                style={{ paddingTop: 0, paddingBottom: 0, paddingRight: '1rem' }}
                                disabled={true}
                                classes={{ disabled: classes.formControlDisabled }}
                              />
                            }
                            classes={{ disabled: classes.formControlDisabled }}
                            label="Shared with Patient"
                          />
                        )}
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails classes={{ root: classes.expansionPanelDetailRoot }}>
                        <CustomRichTextEditor
                          initialValue={initialValue}
                          isReadOnly={isReadOnly || !can(providerGroupPermissions.updateAddendum)}
                          onChange={this.handleChangeNoteBody(note.id)}
                          templateVariables={{
                            date: moment().format('l'),
                            ...editorTemplateVariables
                          }}
                        />
                      </ExpansionPanelDetails>
                      <div className={classes.expansionPanelFooter}>
                        <div style={{ flex: '1 1 auto' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={note.visibleToPatient}
                                value="visibleToPatient"
                                style={{ paddingTop: 0, paddingBottom: 0, paddingRight: '1rem' }}
                                disabled={true}
                                classes={{ disabled: classes.formControlDisabled }}
                              />
                            }
                            classes={{ disabled: classes.formControlDisabled }}
                            label="Shared with Patient"
                          />
                        </div>
                      </div>
                    </ExpansionPanel>
                  );
                }

                // EDITABLE NOTE
                return (
                  <ExpansionPanel
                    expanded={expanded === note.id}
                    onChange={this.handleExpandPanel(note.id)}
                    className={classes.expansionPanel}
                    key={note.id}
                  >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <div style={{ display: 'flex', flex: '1 1 auto' }}>
                        <Typography className={classes.heading} justify={'center'} align={'left'} component={'div'}>
                          {noteTypeText}
                          <Typography className={classes.subHeading}>{lastSigned}</Typography>
                        </Typography>
                        {note.isDirty && <Icon className={classnames(['fas fa-pencil-alt', classes.editIcon])} />}
                      </div>
                      {expanded !== note.id && (
                        <Fragment>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={note.visibleToPatient}
                                value="visibleToPatient"
                                color="primary"
                                style={{ paddingTop: 0, paddingBottom: 0, paddingRight: '1rem' }}
                                classes={{ disabled: classes.formControlDisabled }}
                              />
                            }
                            label="Shared with Patient"
                            onClick={
                              !isSigned && can(providerGroupPermissions.updateNotePatientVisibility)
                                ? evt => {
                                  this.handleToggleShare(note.id)(evt);
                                }
                                : null
                            }
                            disabled={isSigned || !can(providerGroupPermissions.updateNotePatientVisibility)}
                            classes={{ disabled: classes.formControlDisabled }}
                          />
                        </Fragment>
                      )}
                    </ExpansionPanelSummary>
                    {/* following container is required to constrain draggable area */}
                    <div style={{ display: expanded !== note.id ? 'none' : 'unset' }}>
                      <ExpansionPanelDetails classes={{ root: classes.expansionPanelDetailRoot }}>
                        <CustomRichTextEditor
                          initialValue={initialValue}
                          onChange={this.handleChangeNoteBody(note.id)}
                          isReadOnly={providerId !== note.providerId || isSigned || !can(providerGroupPermissions.updateAddendum)}
                          templateVariables={{
                            date: moment().format('l'),
                            ...editorTemplateVariables
                          }}
                        />
                      </ExpansionPanelDetails>
                      <div className={classes.expansionPanelFooter}>
                        <div style={{ flex: '1 1 auto' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={note.visibleToPatient}
                                // onChange={this.handleToggleShare(note.id)}
                                value="visibleToPatient"
                                color="primary"
                                style={{ paddingTop: 0, paddingBottom: 0, paddingRight: '1rem' }}
                                classes={{ disabled: classes.formControlDisabled }}
                              />
                            }
                            label="Shared with Patient"
                            onClick={
                              !isSigned && can(providerGroupPermissions.updateNotePatientVisibility)
                                ? evt => {
                                  this.handleToggleShare(note.id)(evt);
                                }
                                : null
                            }
                            disabled={isSigned || !can(providerGroupPermissions.updateNotePatientVisibility)}
                            classes={{ disabled: classes.formControlDisabled }}
                          />
                        </div>
                        {/* allow discard of your own unsigned notes */}
                        {providerId === note.providerId && !isSigned && (
                          <Button
                            variant={'outlined'}
                            style={{ color: theme.palette.primary.main }}
                            onClick={this.handleShowDeleteDialog(note.id)}
                            disabled={isSigned}
                          >
                            {'DISCARD'}
                          </Button>
                        )}
                        {note.signedDate && note.isDirty === true && (
                          <Button
                            variant={'outlined'}
                            style={{ color: theme.palette.primary.main }}
                            onClick={() => this.handleDiscardChanges(note.id)}
                          >
                            {'DISCARD CHANGES'}
                          </Button>
                        )}
                      </div>
                    </div>
                  </ExpansionPanel>
                );
              })
            )}
          {!isReadOnly && can(providerGroupPermissions.createAddendum) && (
            <div className={classes.noteActions}>
              <Button
                variant={'outlined'}
                style={{ margin: '1rem', color: theme.palette.primary.main }}
                onClick={this.handleShowAddNoteModal}
              >
                {'Add Addendum'}
              </Button>
            </div>
          )}
        </div>

        {/* Delete Confirmation */}
        {!isReadOnly && (
          <CustomDialog
            open={isShowDeleteNoteDialog}
            handleClose={this.handleCloseDeleteDialog}
            content={
              <Typography variant="subtitle1" id="modal-title">
                This will delete the addendum from the patient chart.
              </Typography>
            }
            title={'Delete Addendum'}
            handleAction={() => this.handleDeleteNote(noteToDelete)}
          />
        )}

        {!isReadOnly && (
          <CustomModal handleClose={this.handleCloseAddNoteDialog} open={isShowAddNoteDialog} handleCreateNote={this.handleCreateNote}>
            <div style={{ width: 400, padding: '1rem' }}>
              <Typography variant={'h6'} style={{ marginBottom: '1rem' }}>
                {'Add a Addendum'}
              </Typography>
              <ChartPatientNoteTemplateForm
                noteTypes={noteTypes}
                handleCreateNote={this.handleCreateNote}
                handleCancel={this.handleCloseAddNoteDialog}
              />
            </div>
          </CustomModal>
        )}
      </div>
    );
  }
}

ChartPatientAddendums.defaultProps = {
  isReadOnly: false
};

ChartPatientAddendums.propTypes = {
  classes: PropTypes.object.isRequired,
  visitId: PropTypes.string,
  noteTypes: PropTypes.array.isRequired,
  notes: PropTypes.array.isRequired,
  isReadOnly: PropTypes.bool,
  addChartNote: PropTypes.func,
  deleteChartNote: PropTypes.func,
  providerId: PropTypes.string,
  providerName: PropTypes.string,
  patientName: PropTypes.string,
  patientDob: PropTypes.string,
  patientGender: PropTypes.string
};

let withStylesAddendums = withStyles(styles, { withTheme: true })(ChartPatientAddendums);

const mapStateToProps = state => {
  return {};
};

withStylesAddendums = connect(
  mapStateToProps,
  {
    showToast,
    setNoteVisibility
  }
)(withStylesAddendums);

export default withStylesAddendums;

import getStore from '../../store/store';
const store = getStore();

export const can = (action, entranceId) => {
  const state = store.getState();
  const permissions = state.login.permissions;
  const profile = state.provider.profile;
  const currentChartVisit = state.chart.currentChartVisit;
  const providerGroups = profile ? profile.groups : [];

  //If an id has been passed check just the permissions that match that entranceId ( used for data tables )
  //If null is passed we are in the context of a visit and should use the id of the current selectedVisit

  let id = entranceId ? entranceId : currentChartVisit ? currentChartVisit.entranceId : null;

  if (id === null) {
    return false;
  }

  //Go get the providerGroupIds that are mapped to that entrance.
  let providerGroupIds = [];
  providerGroups.forEach(pg => {
    if (pg.entrances && pg.entrances.some(entranceId => entranceId === id)) {
      providerGroupIds.push(pg.id);
    }
  });

  let idPermissions = [];
  permissions.forEach(permission => {
    if (providerGroupIds && providerGroupIds.some(id => permission.role.startsWith(id))) {
      idPermissions = idPermissions.concat(permission.permissions);
    }
  });

  return idPermissions.includes(action);
};

import firebase from 'firebase';
import { has } from 'lodash';
import history from './history';
import { CALL_API } from './middleware/api';
import {
  updateBoardVisitStatus,
  getBoardItems,
  getActiveBoardItems,
  getAssignedBoardItems,
  getNewBoardItems,
} from './modules/board/board.actions';
import { showToast, updateLayoutVisitStatus, updateLayoutVoiceVisitStatus } from './modules/layout/layout.actions';
import { visitStatusTypes, phoneVisitStatusTypes } from './types/visitStatusTypes';
import { setAvailability } from './modules/board/provider.actions';

require('firebase/firestore');

const FCM_REGISTER_REQUEST = 'FCM_REGISTER_REQUEST';
const FCM_REGISTER_SUCCESS = 'FCM_REGISTER_SUCCESS';
const FCM_REGISTER_FAILURE = 'FCM_REGISTER_FAILURE';

const FCM_DEREGISTER_REQUEST = 'FCM_DEREGISTER_REQUEST';
const FCM_DEREGISTER_SUCCESS = 'FCM_DEREGISTER_SUCCESS';
const FCM_DEREGISTER_FAILURE = 'FCM_DEREGISTER_FAILURE';

const GET_FIREBASE_AUTH_TOKEN_REQUEST = 'GET_FIREBASE_AUTH_TOKEN_REQUEST';
const GET_FIREBASE_AUTH_TOKEN_SUCCESS = 'GET_FIREBASE_AUTH_TOKEN_SUCCESS';
const GET_FIREBASE_AUTH_TOKEN_FAILURE = 'GET_FIREBASE_AUTH_TOKEN_FAILURE';

export const VISIT_STATE_UPDATE = 'VISIT_STATE_UPDATE';
export const AVAILABILITY_UPDATE = 'AVAILABILITY_UPDATE';
export const CALL_STATUS = 'CALL_STATUS';

const TWILIO_VOICE_TIMEOUT = 10; // Twilio has a 60 second timeout on ringing

const FCM_TOKEN_LS_KEY = 'fcmToken';

let store;

let callRingingInterval;
let timeRinging = 0;

export const initializeFirebase = s => {
  store = s;

  firebase.initializeApp({
    messagingSenderId: '180827047577',
    apiKey: 'AIzaSyA2_2eSBU4I1ZOIxveMll4mAKPVYoyiqTU',
    authDomain: 'bison-notify.firebaseapp.com',
    databaseURL: 'https://bison-notify.firebaseio.com',
    projectId: 'bison-notify',
  });

  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();

    // Callback fired if Instance ID token is updated.
    messaging.onTokenRefresh(function () {
      messaging
        .getToken()
        .then(function (refreshedToken) {
          // currentToken = refreshedToken;
          console.log('Token refreshed.');
          // save token and send to app server
          sendTokenToServer(refreshedToken);
        })
        .catch(function (err) {
          console.log('Unable to retrieve refreshed token ', err);
        });
    });

    // handles background messages
    // navigator.serviceWorker.addEventListener('message', event => {
    //   if (event.data && has(event.data, 'firebase-messaging-msg-data')) {
    //     const payload = event.data['firebase-messaging-msg-data'];
    //     console.log('Background message received from SW: ', payload);
    //     handleDataMessage(payload);
    //   } else if (event.data) {
    //     console.log('Background message received from SW: ', event.data);
    //     handleDataMessage(event.data);
    //   }
    // });

    // Handle incoming messages. Called when:
    // - a message is received while the app has focus
    // - the user clicks on an app notification created by a service worker
    //   `messaging.setBackgroundMessageHandler` handler.
    messaging.onMessage(function (payload) {
      console.log('Message received. ', payload);

      // If this is a display notification the payload will have a notification attribute
      if (payload.notification) {
        store.dispatch(showToast(`${payload.notification.title}: ${payload.notification.body}`, 'notification'));
      }
    });
  }
};

const handleFirestoreMessage = async data => {
  const currentState = store.getState();

  switch (data.type) {
    case VISIT_STATE_UPDATE:
      let selectedVisitId = null;

      if (currentState && currentState.layout && currentState.layout.selectedVisit) {
        selectedVisitId = currentState.layout.selectedVisit.id;
      }

      const visitId = data.visitId;

      if (data.oldState === '' && data.newState === visitStatusTypes.NEW) {
        // dont update loading state (send true param)
        await store.dispatch(getBoardItems(true));
        await store.dispatch(getAssignedBoardItems(true));
        await store.dispatch(getNewBoardItems(true));
        await store.dispatch(getActiveBoardItems(true));
      } else {
        await store.dispatch(getBoardItems(true));
        await store.dispatch(getAssignedBoardItems(true));
        await store.dispatch(getNewBoardItems(true));
        await store.dispatch(getActiveBoardItems(true));
        // We don't receive a call status update when returning to the waiting room
        if (callRingingInterval && data.state === visitStatusTypes.NEW) {
          clearInterval(callRingingInterval);
        }

        // update main layout and board reducers
        await store.dispatch(updateBoardVisitStatus(data));
        await store.dispatch(updateLayoutVisitStatus(data));

        if (selectedVisitId !== null && selectedVisitId === visitId) {
          if (data.oldState === visitStatusTypes.SELECTED && data.newState === visitStatusTypes.CANCELED) {
            store.dispatch(showToast('The patient has canceled their visit'));
          } else if (data.newState === visitStatusTypes.STARTED && window.location.pathname !== `/visit/${data.visitId}`) {
            history.push(`/visit/${data.visitId}`);
          }
        }
      }

      break;

    case AVAILABILITY_UPDATE:
      store.dispatch(setAvailability(data));
      break;

    case CALL_STATUS:
      /*
        STATUS:
        ** RINGING - VISIT SELECTED & CALL OUTGOING
        ** IN_PROGRESS - VISIT STARTED & CALL ANSWERED
          - Patient picks up
          - Answering machine picks up
        ** COMPLETED - VISIT COMPLETED w/ 3 states
          - Twilio timeouts
          - Call ends
      */
      if (data.status === phoneVisitStatusTypes.RINGING) {
        timeRinging = 0;
        callRingingInterval = setInterval(async () => {
          timeRinging += 1;
        }, 1000);
      }

      if (data.status === phoneVisitStatusTypes.IN_PROGRESS) {
        clearInterval(callRingingInterval);
      } else if (data.status === phoneVisitStatusTypes.NO_ANSWER || timeRinging > TWILIO_VOICE_TIMEOUT) {
        data.isUnreached = true;
        clearInterval(callRingingInterval);
      }

      store.dispatch(updateLayoutVoiceVisitStatus(data));
      break;

    default:
  }
};

export const fcmRegister = token => {
  return {
    [CALL_API]: {
      types: [FCM_REGISTER_REQUEST, FCM_REGISTER_SUCCESS, FCM_REGISTER_FAILURE],
      authenticated: true,
      endpoint: 'v1/fcm/register',
      method: 'POST',
      payload: {
        token,
      },
    },
  };
};

export const fcmDeregister = token => {
  return {
    [CALL_API]: {
      types: [FCM_DEREGISTER_REQUEST, FCM_DEREGISTER_SUCCESS, FCM_DEREGISTER_FAILURE],
      authenticated: true,
      endpoint: 'v1/fcm/deregister',
      method: 'POST',
      payload: {
        token,
      },
    },
  };
};

const getFirebaseAuthToken = () => {
  return {
    [CALL_API]: {
      types: [GET_FIREBASE_AUTH_TOKEN_REQUEST, GET_FIREBASE_AUTH_TOKEN_SUCCESS, GET_FIREBASE_AUTH_TOKEN_FAILURE],
      authenticated: true,
      endpoint: 'v1/fcm/token',
      method: 'POST',
    },
  };
};

const deleteOldSnapshots = (docs) => {
  const timer = ms => new Promise(res => setTimeout(res, ms));

  (async () => {
    for (let i = 0; i < docs.length; i++) {
      try {
        const doc = docs[i];
        await doc.ref.delete();
        await timer(100);
      } catch (err) {
        // Do nothing.
      }
    }
  })();
}

export const initFirestore = async () => {
  try {
    const result = await store.dispatch(getFirebaseAuthToken());
    if (result.type === GET_FIREBASE_AUTH_TOKEN_SUCCESS) {
      if (has(result.response, 'token')) {
        await firebase.auth().signInWithCustomToken(result.response.token);
        const user = firebase.auth().currentUser;

        // delete old messages since last login
        const db = firebase.firestore();
        const snapshot = await db.collection('notifications').where('uid', '==', user.uid).get();

        // deleteOldSnapshots(snapshot.docs);

        db.collection('notifications')
          .where('uid', '==', user.uid)
          .onSnapshot(async snapshot => {
            const additions = [];
            snapshot.docChanges().forEach(change => {
              if (change.type === 'added') {
                additions.push(change.doc);
              }
            });
            for (const not of additions) {
              const data = not.data();
              // Do something with it
              handleFirestoreMessage(data);
              try {
                await not.ref.delete();
              } catch (err) {
                // possibly deleted from another tab/browser
              }
            }
          });
      }
    }
  } catch (e) {
    console.log('error during init firestore:', e);
  }
};

const setFcmToken = token => {
  window.localStorage.setItem(FCM_TOKEN_LS_KEY, token);
};

const getFcmToken = () => {
  window.localStorage.getItem(FCM_TOKEN_LS_KEY);
};

const clearFcmToken = () => {
  window.localStorage.removeItem(FCM_TOKEN_LS_KEY);
};

// Send the Instance ID token your application server, so that it can:
// - send messages back to this app
// - subscribe/unsubscribe the token from topics
const sendTokenToServer = async token => {
  console.log('Sending token to server...');
  const response = await store.dispatch(fcmRegister(token));
  if (response.type === FCM_REGISTER_SUCCESS) {
    setFcmToken(token);
  } else {
    console.log('Registering for notifications failed');
  }
};

export const requestNotificationPermission = async () => {
  try {
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      await messaging.requestPermission();
      const token = await messaging.getToken();
      console.log('obtained fcm token: ', token);
      sendTokenToServer(token);
      return token;
    }
  } catch (error) {
    console.error(error);
  }
};

export const clearNotificationRegistration = async () => {
  console.log('Deregistering for notifications...');
  const token = getFcmToken();
  if (token) {
    const response = await store.dispatch(fcmDeregister(token));
    if (response.type === FCM_DEREGISTER_SUCCESS) {
      clearFcmToken();
    } else {
      console.log('Deregistering for notifications failed');
    }
  }
};

import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import history from '../../history';
import { connect } from 'react-redux';
import { withStyles, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ChatBubble from '@material-ui/icons/ChatBubble';
import Phone from '@material-ui/icons/Phone';
import Video from '@material-ui/icons/Videocam';
import Assignment from '@material-ui/icons/Assignment';

import { isNil } from 'lodash';
import RelyMDPrimaryButton from '../../common/buttons/relyMDPrimaryButton.component';
import { can } from '../login/can';
import { providerGroupPermissions } from '../../types/permissionTypes';
import { visitStatusTypes, phoneVisitStatusTypes } from '../../types/visitStatusTypes';
import { initiatePhoneCall as initiatePhoneCallAction, phoneCallStatus as phoneCallStatusAction } from '../visit/visit.actions';

export const useInterval = (fn, delay) => {
  const callback = useRef(fn);

  useEffect(() => {
    callback.current = fn;
  }, [fn]);

  useEffect(() => {
    const tick = () => {
      callback.current();
    };

    if (delay !== null) {
      const id = setInterval(tick, delay);

      return () => clearInterval(id);
    }
  }, [delay]);
};

const BannerActions = ({
  classes,
  disabled,
  selectedVisit: { id, entranceId, facility, isUnreached, isVoiceCall, state, voiceState },
  onCancelVisitClick,
  onConvertToVoiceClick,
  onMessageClick,
  onStartVisit,
  onRedialClick,
  onWaitingRoomClick,
  phoneCallStatusRequest,
  initiatePhoneCallRequest,
  videoConnectionStatus,
}) => {
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [isReturnOpen, setIsReturnOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [phoneCallStatus, setPhoneCallStatus] = useState('disconnected');
  const [phoneCallInProgress, setPhoneCallInProgress] = useState(false);

  const handleOpenMenu = useCallback(
    openMenu => event => {
      setAnchorEl(event.target);
      openMenu(prev => !prev);
    },
    [setAnchorEl]
  );

  const handleCloseMenu = useCallback(
    openMenu => event => {
      if (anchorEl.contains(event.target)) return;

      openMenu(false);
    },
    [anchorEl]
  );

  const isFacilitatedVisit = !isNil(facility);
  /*
    Should show redial when:
    - Is a voice call
    - Not a facilitated visit
    - Is Unreached
    - Call is Completed
    - Or call state is started and voice state has not been set (This happens on a fresh page reload)
    */
  const showRedial =
    isVoiceCall &&
    !isFacilitatedVisit &&
    (isUnreached || voiceState === phoneVisitStatusTypes.COMPLETED || (state === visitStatusTypes.STARTED && isNil(voiceState)));

  const isOnVisitPage = useMemo(() => {
    return window.location.pathname === `/visit/${id}`;
  }, [window.location.pathname, id]);

  const getCallStatus = useCallback(async () => {
    setPhoneCallInProgress(true);

    const { response } = await phoneCallStatusRequest(id);

    setPhoneCallStatus(response.status);

    if (response.status === 'in-progress' || response.status === 'queued' || response.status === 'ringing') {
      setPhoneCallInProgress(true);
    } else {
      setPhoneCallInProgress(false);
    }
  }, [phoneCallStatusRequest, id]);

  const initiatePhoneCall = useCallback(async () => {
    setPhoneCallInProgress(true);

    await initiatePhoneCallRequest(id);
  }, [initiatePhoneCallRequest, id]);

  const handleCallPatientClick = useCallback(async () => {
    setPhoneCallInProgress(true);

    await initiatePhoneCall();
    await getCallStatus();
  }, [initiatePhoneCall, getCallStatus, id]);

  const handleViewChartClick = useCallback(async () => {
    await history.push(`/visit/${id}`);
  }, [id]);

  const handleJoinVideoClick = useCallback(async () => {
    if (videoConnectionStatus === 'connected') {
      window.dispatchEvent(new CustomEvent('rmd.patient_banner.disconnect_video'));
    } else if (videoConnectionStatus === 'disconnected') {
      if (!isOnVisitPage) {
        await history.push(`/visit/${id}`);
      }

      window.dispatchEvent(new CustomEvent('rmd.patient_banner.initiate_video'));
    }
  }, [isOnVisitPage, videoConnectionStatus]);

  const joinVideoButtonText = useMemo(() => {
    if (videoConnectionStatus === 'connected') {
      return 'disconnect video';
    } else if (videoConnectionStatus === 'disconnected') {
      return 'join video';
    } else {
      return 'joining...';
    }
  }, [videoConnectionStatus]);

  const callPatientButtonText = useMemo(() => {
    if (!phoneCallInProgress) {
      return 'call patient';
    }

    return phoneCallStatus;
  }, [phoneCallInProgress, phoneCallStatus]);

  const isVideoConnecting = useMemo(() => {
    return videoConnectionStatus === 'connecting';
  }, [videoConnectionStatus]);

  const handleEndVisitClick = useCallback(async () => {
    if (!isOnVisitPage) {
      await history.push(`/visit/${id}`);
    }

    window.dispatchEvent(new CustomEvent('rmd.patient_banner.end_visit'));
  }, [isOnVisitPage]);

  useInterval(getCallStatus, phoneCallInProgress ? 5000 : null);

  useEffect(() => {
    window.addEventListener('rmd.patient_banner.get_call_status', getCallStatus);
    window.addEventListener('rmd.patient_banner.initiate_phone', initiatePhoneCall);

    return () => {
      window.removeEventListener('rmd.patient_banner.get_call_status', getCallStatus);
      window.removeEventListener('rmd.patient_banner.initiate_phone', initiatePhoneCall);
    };
  }, [getCallStatus, initiatePhoneCall]);

  return (
    <>
      {(state === visitStatusTypes.STARTED || state === visitStatusTypes.SELECTED || state === visitStatusTypes.READY) && (
        <>
          <RelyMDPrimaryButton
            aria-owns={isReturnOpen ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            className={classes.actionButton}
            disabled={disabled}
            variant="contained"
            onClick={handleOpenMenu(setIsReturnOpen)}
          >
            manage visit
            <ArrowDropDown style={{ marginTop: -2 }} />
          </RelyMDPrimaryButton>
          <Popper open={isReturnOpen} anchorEl={anchorEl} transition disablePortal style={{ zIndex: 1000 }}>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper style={{ minWidth: 200 }}>
                  <ClickAwayListener onClickAway={handleCloseMenu(setIsReturnOpen)}>
                    <MenuList>
                      <MenuItem onClick={handleEndVisitClick}>End Visit</MenuItem>
                      <MenuItem onClick={() => onWaitingRoomClick(false)}>Return to Waiting Room</MenuItem>
                      <MenuItem onClick={() => onCancelVisitClick(false)}>Cancel Visit</MenuItem>
                      <MenuItem onClick={() => onCancelVisitClick(true)}>Cancel Visit But Still Chart</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          {!isOnVisitPage && (
            <RelyMDPrimaryButton variant="contained" className={classes.actionButton} disabled={disabled} onClick={handleViewChartClick}>
              <div className={classNames(classes.phoneIcon)}>
                <Assignment className={classNames(classes.icon)} />
              </div>
              <div className={classNames(classes.phoneText)}>view chart</div>
            </RelyMDPrimaryButton>
          )}
          <RelyMDPrimaryButton
            variant="contained"
            className={classes.actionButton}
            disabled={disabled || isVideoConnecting}
            onClick={handleJoinVideoClick}
          >
            <div className={classNames(classes.phoneIcon)}>
              <Video className={classNames(classes.icon)} />
            </div>
            <div className={classNames(classes.phoneText)}>{joinVideoButtonText}</div>
          </RelyMDPrimaryButton>

          <RelyMDPrimaryButton
            variant="contained"
            className={classes.actionButton}
            disabled={disabled || phoneCallInProgress}
            onClick={handleCallPatientClick}
          >
            <div className={classNames(classes.phoneIcon)}>
              <Phone className={classNames(classes.icon)} />
            </div>
            <div className={classNames(classes.phoneText)}>{callPatientButtonText}</div>
          </RelyMDPrimaryButton>
        </>
      )}
      {!isVoiceCall && can(providerGroupPermissions.createPatientMessage, entranceId) && (
        <RelyMDPrimaryButton className={classes.actionButton} disabled={disabled} onClick={onMessageClick}>
          <ChatBubble />
        </RelyMDPrimaryButton>
      )}
      {showRedial && (
        <RelyMDPrimaryButton className={classes.actionButton} disabled={disabled} onClick={onRedialClick}>
          redial
        </RelyMDPrimaryButton>
      )}
    </>
  );
};

const styles = theme => ({
  actionButton: {
    minHeight: theme.spacing.unit * 4.5,
    marginRight: theme.spacing.unit * 2,
  },
  phoneIcon: {
    lineHeight: 1,
  },
  icon: {
    fontSize: '1.2rem',
    lineHeight: 1,
  },
  phoneText: {
    marginLeft: '.8rem',
  },
});

BannerActions.propTypes = {
  classes: PropTypes.object.isRequired,

  disabled: PropTypes.bool.isRequired,
  videoConnectionStatus: PropTypes.string.isRequired,
  providerId: PropTypes.string.isRequired,
  selectedVisit: PropTypes.object.isRequired,

  onCancelVisitClick: PropTypes.func.isRequired,
  onConvertToVoiceClick: PropTypes.func.isRequired,
  onMessageClick: PropTypes.func.isRequired,
  onStartVisit: PropTypes.func.isRequired,
  onWaitingRoomClick: PropTypes.func.isRequired,
  onRedialClick: PropTypes.func.isRequired,
  initiatePhoneCallRequest: PropTypes.func.isRequired,
  phoneCallStatusRequest: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isLoading: state.visit.isLoading,
  videoConnectionStatus: state.visit.videoConnectionStatus,
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    initiatePhoneCallRequest: initiatePhoneCallAction,
    phoneCallStatusRequest: phoneCallStatusAction,
  })(BannerActions)
);

import { Redirect, Route, Switch, matchPath, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import { LOGIN_STATE } from '../login/login.actions';
import { withStyles } from '@material-ui/core/styles';
import Header from './header.container';
import LoginPage from '../login/login.container';
import Callback from '../callback/callback.container';
import ResizeHandler from './resizeHandler.component';
import Toast from './toast.container';
import DocumentTitle from 'react-document-title';
import DocumentTitleFormatter from '../../utilities/documentTitleFormatter';
import MapRouteTitle from '../../utilities/mapRouteTitle';
import Auth, { INACTIVITY_TIMEOUT_MS, INACTIVITY_TIMEOUT_POLLING_INTERVAL_MS } from '../login/auth';
import PatientBanner from '../patientBanner/patientBanner.component';
import { clearAuth, setAuth } from '../login/login.actions';
import { getBoardItems, GET_BOARD_ITEMS_SUCCESS } from '../board/board.actions';
import { visitStatusTypes } from '../../types/visitStatusTypes';
import { setSelectedVisit, setLayoutInitializing, clearLayoutBeforeUnload, getMessageBanner } from './layout.actions';
import Routes from './routes/routes.component';
import { getProfile } from '../board/provider.actions';
import LoadingOverlay from '../../common/loadingOverlay/loadingOverlay.component';
import { getNoteTypes } from '../chart/chart.actions';
import { requestNotificationPermission, initFirestore } from '../../fcm';
import moment from 'moment';
import ConfirmChargesModal from './confirmChargesModal.container';
import { has, isNil } from 'lodash';
import { hasWebcam, hasMicrophone } from '../../utilities/hardwareUtils';
import { isInTimeRange } from '../../utilities/dataUtils';
import MessageBanner from './messageBanner.component';

const styles = theme => ({
  contentWrapper: {
    height: '100vh',
    flexGrow: 1,
    zIndex: 1,
    position: 'relative',
    display: 'flex',
    maxWidth: '100%',
  },
  content: {
    overflow: 'auto',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F5F7FA',
    // padding: theme.spacing.unit * 3,
    paddingTop: 64, // header height
    maxWidth: 'calc(100% - 72px)',
  },
});

class MainContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.auth = new Auth(this.props.history, props.setAuth, props.clearAuth, this.onAuthSet);
    this._lastActivityTime = moment().valueOf();
    this._debounceActivityTimeout = null;
  }

  async componentWillMount() {
    let rawIdToken = localStorage.getItem('id_token');
    let rawAccessToken = localStorage.getItem('access_token');

    // ensure we set auth before checking loginState
    await this.props.setAuth(rawIdToken, rawAccessToken);

    if (this.props.loginState === LOGIN_STATE.LOGGED_IN) {
      this.initLayout();
      this.initActivityTimer();
    } else {
      this.props.setLayoutInitializing(false);
    }
  }

  componentWillUnmount() {
    if (this._activityCheckInterval) clearInterval(this._activityCheckInterval);
    if (this._debounceActivityTimeout) clearTimeout(this._debounceActivityTimeout);
  }

  // callback on initial login
  onAuthSet = () => {
    if (this.props.loginState === LOGIN_STATE.LOGGED_IN) {
      this.initLayout();
      this.initActivityTimer();
    }
  };

  // kick off polling interval to check time of last user activity
  initActivityTimer = () => {
    if (this._activityCheckInterval) clearInterval(this._activityCheckInterval);
    this._activityCheckInterval = setInterval(async () => {
      let diff = moment().diff(this._lastActivityTime);
      // check if we are in a visit route
      if (diff > INACTIVITY_TIMEOUT_MS && this.props.location.pathname.indexOf('visit/') === -1) {
        // redirect in case a chart was in progress
        this.props.clearLayoutBeforeUnload();
        await this.props.history.push('/');
        this.auth.logout();
      }
    }, INACTIVITY_TIMEOUT_POLLING_INTERVAL_MS);
  };

  // debounce user activity for performance
  trackUserActivity = evt => {
    clearTimeout(this._debounceActivityTimeout);
    this._debounceActivityTimeout = setTimeout(() => {
      this._lastActivityTime = moment().valueOf();
    }, 500);
  };

  // fetch required layout data
  initLayout = async () => {
    // Setup FCM Notifications
    requestNotificationPermission();

    // Setup firestore
    await initFirestore();

    // get provider profile information
    await this.props.getProfile();

    // get note types
    this.props.getNoteTypes();

    // check for message banners
    await this.props.getMessageBanner();

    // get board items, set selected visit if there is one
    let response = await this.props.getBoardItems();

    if (response.type === GET_BOARD_ITEMS_SUCCESS) {
      if (has(response, 'response.data') && response.response.data.length > 0) {
        let selectedVisit = response.response.data.find(item => {
          return (
            item.state === visitStatusTypes.SELECTED || item.state === visitStatusTypes.READY || item.state === visitStatusTypes.STARTED
          );
        });
        if (selectedVisit) {
          await this.props.setSelectedVisit(selectedVisit);
          if (
            (selectedVisit.state === visitStatusTypes.STARTED ||
              selectedVisit.state === visitStatusTypes.SELECTED ||
              selectedVisit.state === visitStatusTypes.READY) &&
            this.props.location.pathname.indexOf('visit/') === -1
          ) {
            this.props.history.push(`visit/${selectedVisit.id}`);
          }
        }
      }
    }

    // init complete
    this.props.setLayoutInitializing(false);
  };

  shouldShowBackButton = () => {
    const { pathname } = this.props.location;
    const nonTerminalRoutes = ['/', '/users', '/logout'];

    let isShowBackButton = true;
    for (let route of nonTerminalRoutes) {
      if (
        matchPath(pathname, {
          path: route,
          exact: true,
          strict: false,
        })
      ) {
        isShowBackButton = false;
      }
    }

    return isShowBackButton;
  };

  // only show message banner on certain routes
  // only show in timeframe if provided
  shouldShowMessageBanner = () => {
    const { messageBanner } = this.props;
    if (!isNil(messageBanner)) {
      const { pathname } = this.props.location;
      const showMessageRoutes = ['/', '/board'];

      let showMessageBanner = false;
      for (let route of showMessageRoutes) {
        if (
          matchPath(pathname, {
            path: route,
            exact: true,
            strict: false,
          })
        ) {
          showMessageBanner = true;
        }
      }

      if (!isNil(messageBanner.startTime) && showMessageBanner === true) {
        showMessageBanner = isInTimeRange(messageBanner.startTime, messageBanner.endTime);
      }

      return showMessageBanner;
    }
    return false;
  };

  render() {
    let {
      loginState,
      classes,
      isProviderLoading,
      isNoteTypesLoading,
      isBoardLoading,
      isLayoutInitializing,
      authSet,
      messageBanner,
      location,
    } = this.props;

    if (isProviderLoading || isNoteTypesLoading || isBoardLoading || isLayoutInitializing || !authSet) {
      return <LoadingOverlay />;
    }

    return (
      <div className={classes.contentWrapper} onMouseMove={this.trackUserActivity} onKeyDown={this.trackUserActivity}>
        {loginState === LOGIN_STATE.LOGGED_IN ? (
          <DocumentTitle title={DocumentTitleFormatter(MapRouteTitle(location))}>
            <Fragment>
              <ResizeHandler />
              <Header shouldShowBackButton={this.shouldShowBackButton()} />
              <main className={classes.content}>
                {(!hasWebcam || !hasMicrophone) && (
                  <MessageBanner
                    message={'Start a Visit is disabled. To start a visit it is required that you have a microphone and a camera.'}
                  />
                )}
                {this.shouldShowMessageBanner() && <MessageBanner message={messageBanner.text} type={messageBanner.state} />}
                <PatientBanner />
                <Routes loginState={loginState} auth={this.auth} />
              </main>
            </Fragment>
          </DocumentTitle>
        ) : (
          <Switch>
            <Route exact path="/login" render={props => <LoginPage auth={this.auth} {...props} />} />
            <Route
              exact
              path="/callback"
              render={props => {
                this.auth.handleAuthentication(props);
                return <Callback {...props} />;
              }}
            />
            <Redirect from="*" to="/login" />
          </Switch>
        )}
        <Toast />
        <ConfirmChargesModal />
      </div>
    );
  }
}

MainContainer.contextTypes = {
  router: PropTypes.object,
};

MainContainer.propTypes = {
  loginState: PropTypes.number.isRequired,

  // Injected by React Router
  children: PropTypes.node,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    loginState: state.login.loginState,
    isProviderLoading: state.provider.isLoading,
    isNoteTypesLoading: state.chart.isNoteTypesLoading,
    isBoardLoading: state.layout.isLoading,
    isLayoutInitializing: state.layout.isInitializing,
    authSet: state.login.authSet,
    messageBanner: state.layout.messageBanner,
  };
};

const withRouterMainContainer = withRouter(
  connect(mapStateToProps, {
    setAuth,
    clearAuth,
    getProfile,
    getNoteTypes,
    getBoardItems,
    setSelectedVisit,
    setLayoutInitializing,
    clearLayoutBeforeUnload,
    getMessageBanner,
  })(MainContainer)
);

export default withStyles(styles, { withTheme: true })(withRouterMainContainer);
